import React, { useState, useEffect } from "react";
import { auth, googleProvider, db, storage } from "../config/firebase.js";
import {
  getFirestore,
  query,
  where,
  collection,
  doc as firestoreDoc,
  getDocs,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "../../src/pages/allCSS.css";
import PartnerContratto from "../../src/pages/contratto.js";

const MyRFQPage = () => {
  const [rfqs, setRfqs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPartner, setIsPartner] = useState(false);
  const [isAssoc, setIsAssoc] = useState(false);
  const [isAzienda, setIsAzienda] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [pendingUpdates, setPendingUpdates] = useState({});
  const [submittedUpdates, setSubmittedUpdates] = useState({});
  const [rfqStatus, setRfqStatus] = useState({});
  const [pendingInputs, setPendingInputs] = useState({});
  const [selectedRFQ, setSelectedRFQ] = useState(null);
  const [showContract, setShowContract] = useState(false);

  const handleOpenRFQ = (rfq) => {
    setSelectedRFQ(rfq);
  };

  const handleCloseRFQ = () => {
    setSelectedRFQ(null);
  };

  const db = getFirestore();
  const auth = getAuth();

  // 🔐 Check Authentication Status
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
        setCurrentUser(user); // You can also fetch additional user data here if needed
      } else {
        setIsAuthenticated(false);
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // 📄 Fetch RFQs based on Role
  // 📄 Fetch RFQs based on Role
  useEffect(() => {
    const fetchRFQs = async () => {
      if (!currentUser) {
        setRfqs([]);
        return;
      }

      try {
        let rfqData = [];

        const userDocSnap = await getDocs(
          query(
            collection(db, "users"),
            where("email", "==", currentUser.email)
          )
        );

        if (userDocSnap.empty) {
          console.error(
            "User data not found in Firestore for:",
            currentUser.email
          );
          setRfqs([]);
          return;
        }

        const userData = userDocSnap.docs[0].data();

        if (userData.role === "partner") {
          setIsPartner(true);
          setIsAzienda(false);

          const rfqMainCollection = await getDocs(collection(db, "rfq"));

          for (const rfqDoc of rfqMainCollection.docs) {
            const partnerSubRef = collection(db, `rfq/${rfqDoc.id}/partners`);
            const partnerDoc = await getDoc(
              doc(partnerSubRef, currentUser.uid)
            );

            if (partnerDoc.exists()) {
              const partnerData = partnerDoc.data();
              rfqData.push({
                rfqId: rfqDoc.id,
                ...rfqDoc.data(),
                partnerDetails: partnerData,
              });
            }
          }
        } else if (userData.role === "azienda") {
          setIsPartner(false);
          setIsAzienda(true);

          const rfqQuery = query(
            collection(db, "rfq"),
            where("userId", "==", currentUser.uid)
          );

          const querySnapshot = await getDocs(rfqQuery);

          for (const rfqDoc of querySnapshot.docs) {
            const partnersCollection = collection(
              db,
              `rfq/${rfqDoc.id}/partners`
            );
            const partnersSnapshot = await getDocs(partnersCollection);

            let partnersData = await Promise.all(
              partnersSnapshot.docs.map(async (partnerDoc) => {
                const partnerId = partnerDoc.id;
                const partnerDetails = partnerDoc.data();

                // Fetch partner's firstName from users collection
                const userRef = doc(db, "users", partnerId);
                const userSnap = await getDoc(userRef);
                const firstName = userSnap.exists()
                  ? userSnap.data().firstName
                  : "Unknown";

                return {
                  partnerId,
                  firstName,
                  UF:
                    partnerDetails.UF != null ? partnerDetails.UF : "in attesa",
                  SF:
                    partnerDetails.SF != null
                      ? `${(partnerDetails.SF * 100).toFixed(2)}%`
                      : "in attesa",
                };
              })
            );

            rfqData.push({
              id: rfqDoc.id,
              ...rfqDoc.data(),
              partners: partnersData,
            });
          }
        } else if (userData.role === "commercialista") {
          setIsPartner(false);
          setIsAzienda(false);

          const rfqQuery = query(
            collection(db, "rfq"),
            where("userId", "==", currentUser.uid)
          );

          const querySnapshot = await getDocs(rfqQuery);

          rfqData = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        } else {
          console.error("Invalid user role:", userData.role);
          setRfqs([]);
          return;
        }

        setRfqs(rfqData);
      } catch (error) {
        console.error("Error fetching RFQs:", error);
        setRfqs([]);
      } finally {
        setLoading(false);
      }
    };

    fetchRFQs();
  }, [currentUser, db]);

  // Function to handle partner choice confirmation
  const handleConfirmPartner = async (rfqId, partnerId, firstName) => {
    const confirm = window.confirm(
      `Do you want to confirm the RFQ with ${firstName}?`
    );
    if (confirm) {
      try {
        const rfqRef = doc(db, "rfq", rfqId);
        await updateDoc(rfqRef, { partnerChoice: partnerId });
        alert("RFQ confirmed successfully!");
      } catch (error) {
        console.error("Error confirming RFQ:", error);
      }
    }
  };

  // 🛠️ Partner Updates SF and UF
  const handlePartnerUpdate = async (rfqId, field, value) => {
    try {
      const partnerSubRef = doc(db, `rfq/${rfqId}/partners/${currentUser.uid}`);

      await updateDoc(partnerSubRef, {
        [field]: value,
      });

      // Update local state after successful update
      setRfqs((prevRfqs) =>
        prevRfqs.map((rfq) =>
          rfq.rfqId === rfqId
            ? {
                ...rfq,
                partnerDetails: {
                  ...rfq.partnerDetails,
                  [field]: value,
                },
              }
            : rfq
        )
      );
    } catch (error) {
      console.error("Error updating partner fields:", error);
    }
  };

  if (!isAuthenticated)
    return <p>🔐 Per favore, effettua il login per accedere alle tue RFQ.</p>;
  if (loading) return <p>Loading RFQs...</p>;

  const handleConfirmAndSubmit = async (rfqId) => {
    const inputs = pendingInputs[rfqId];
    if (!inputs || inputs.UF == null || inputs.SF == null) {
      alert("Please fill in both UF and SF before submitting.");
      return;
    }

    try {
      const partnerSubRef = doc(db, `rfq/${rfqId}/partners/${currentUser.uid}`);

      const dataToUpdate = {
        UF: inputs.UF,
        SF: inputs.SF / 100, // Store as decimal
      };

      //console.log("Submitting SF/UF Data to Firestore:", dataToUpdate);

      await updateDoc(partnerSubRef, dataToUpdate);

      // Clear pending inputs after successful submission
      setPendingInputs((prev) => {
        const updated = { ...prev };
        delete updated[rfqId];
        return updated;
      });

      // Update local state to reflect changes
      setRfqs((prevRfqs) =>
        prevRfqs.map((rfq) =>
          rfq.rfqId === rfqId
            ? {
                ...rfq,
                partnerDetails: {
                  ...rfq.partnerDetails,
                  UF: dataToUpdate.UF,
                  SF: dataToUpdate.SF,
                },
              }
            : rfq
        )
      );

      alert("SF and UF submitted successfully! ✅");
    } catch (error) {
      console.error("Error updating Firestore:", error);
      alert("❌ Failed to submit SF/UF.");
    }
  };

  const handleRfqDecision = async (rfqId, decision) => {
    try {
      const partnerSubRef = doc(db, `rfq/${rfqId}/partners/${currentUser.uid}`);

      const dataToUpdate = {
        request: decision, // 'accept' or 'reject'
        acceptTimestamp: new Date(), // Timestamp for decision
      };

      await updateDoc(partnerSubRef, dataToUpdate);

      //console.log("RFQ Decision Updated in Firestore:", dataToUpdate);

      // Force local state update for re-render
      setRfqs((prevRfqs) =>
        prevRfqs.map((rfq) =>
          rfq.rfqId === rfqId
            ? {
                ...rfq,
                partnerDetails: {
                  ...rfq.partnerDetails,
                  request: decision,
                  acceptTimestamp: dataToUpdate.acceptTimestamp,
                },
              }
            : rfq
        )
      );

      //console.log("RFQ State Updated After Decision:", rfqId, decision);

      alert(`RFQ ${decision === "accept" ? "accepted" : "rejected"}! ✅`);
    } catch (error) {
      console.error("Error updating RFQ decision:", error);
      alert("❌ Failed to update RFQ decision.");
    }
  };

  return (
    <div style={{ backgroundColor: "black" }}>
      <div className="rfq-page-container">
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "45px",
            fontWeight: "bold",
            color: "white",
            textAlign: "center",
            backgroundColor: "black",
            marginTop: "10px",
          }}
        >
          Preventivi
        </h1>

        {/* 🔥 Partner View */}
        {isPartner ? (
          rfqs.map((rfq) => {
            const decision = rfq.partnerDetails?.request; // 'accept', 'reject', or undefined
            const sfValue = rfq.partnerDetails?.SF;
            const ufValue = rfq.partnerDetails?.UF;

            return (
              <div key={rfq.rfqId} className="rfq-card">
                <div key={rfq.rfqId} className="rfq-card2">
                  {/* Incentive Title */}
                  <div className="rfq-title">
                    {rfq.partnerDetails?.TitoloIncentivo || "N/A"}
                  </div>

                  {/* Submission Date */}
                  <p className="rfq-submission-date">
                    Invio:{" "}
                    {new Date(
                      rfq.partnerDetails?.sentTimestamp
                    ).toLocaleString()}
                  </p>

                  {/* Company Info */}
                  <div className="rfq-company-info red-box">
                    <p>
                      <strong>Company Size:</strong>{" "}
                      {rfq.partnerDetails?.CompanySize || "N/A"}
                    </p>
                    <p className="rfq-ateco">
                      <strong>ateco:</strong>{" "}
                      {rfq.partnerDetails?.ATECO
                        ? rfq.partnerDetails.ATECO.toLowerCase()
                        : "n/a"}
                    </p>

                    <p>
                      <strong>Regione:</strong>{" "}
                      {rfq.partnerDetails?.Regione || "N/A"}
                    </p>
                  </div>

                  {/* Conditional Logic */}
                  {(() => {
                    // If request is missing, show Accept/Reject buttons
                    if (!decision) {
                      //console.log("No decision yet. Showing Accept/Reject buttons.");
                      return (
                        <div className="rfq-action-container">
                          <button
                            onClick={() =>
                              handleRfqDecision(rfq.rfqId, "accept")
                            }
                            className="accept-btn"
                          >
                            Accept
                          </button>
                          <button
                            onClick={() =>
                              handleRfqDecision(rfq.rfqId, "reject")
                            }
                            className="reject-btn"
                          >
                            Reject
                          </button>
                        </div>
                      );
                    }

                    // If RFQ is rejected, show rejection message
                    if (decision === "reject") {
                      //console.log("RFQ rejected. Showing rejection message.");
                      return <p className="rfq-rejected">❌ RFQ Rejected</p>;
                    }

                    // If accepted, show SF/UF values or input fields
                    if (decision === "accept") {
                      //console.log("RFQ accepted.");

                      // Fix: Check for null or undefined
                      if (sfValue != null && ufValue != null) {
                        //console.log("SF and UF are submitted. Showing values.");
                        return (
                          <>
                            <p className="rfq-response-date">
                              Risposta:{" "}
                              {rfq.partnerDetails?.acceptTimestamp
                                ? new Date(
                                    rfq.partnerDetails.acceptTimestamp.toDate
                                      ? rfq.partnerDetails.acceptTimestamp.toDate()
                                      : rfq.partnerDetails.acceptTimestamp
                                  ).toLocaleString()
                                : "N/A"}
                            </p>

                            <div className="rfq-values-container">
                              <p>
                                <strong>Richiesta:</strong> {decision}
                              </p>
                              <p>
                                <strong>Fee Iniziale (€):</strong> {ufValue}
                              </p>
                              <p>
                                <strong>Success Fee:</strong> {sfValue * 100}%
                              </p>
                            </div>
                          </>
                        );
                      } else {
                        //console.log("SF and UF not yet submitted. Showing input fields.");
                        // Show input fields for UF and SF
                        return (
                          <div className="rfq-input-container">
                            <label>
                              UF:
                              <input
                                type="number"
                                value={
                                  pendingInputs[rfq.rfqId]?.UF ??
                                  rfq.partnerDetails?.UF ??
                                  ""
                                }
                                onChange={(e) => {
                                  const value =
                                    e.target.value === ""
                                      ? null
                                      : parseFloat(e.target.value);
                                  setPendingInputs((prev) => ({
                                    ...prev,
                                    [rfq.rfqId]: {
                                      ...prev[rfq.rfqId],
                                      UF: value,
                                    },
                                  }));
                                  //console.log("Pending UF Value:", value);
                                }}
                                placeholder="Enter UF"
                              />
                            </label>

                            <label>
                              SF (%):
                              <input
                                type="number"
                                min="0"
                                max="100"
                                value={
                                  pendingInputs[rfq.rfqId]?.SF ??
                                  (rfq.partnerDetails?.SF != null
                                    ? rfq.partnerDetails.SF * 100
                                    : "")
                                }
                                onChange={(e) => {
                                  const value =
                                    e.target.value === ""
                                      ? null
                                      : parseFloat(e.target.value);
                                  setPendingInputs((prev) => ({
                                    ...prev,
                                    [rfq.rfqId]: {
                                      ...prev[rfq.rfqId],
                                      SF: value,
                                    },
                                  }));
                                  console.log("Pending SF Value:", value);
                                }}
                                placeholder="Enter SF (%)"
                              />
                            </label>

                            {/* Confirm and Submit Button */}
                            <button
                              onClick={() => handleConfirmAndSubmit(rfq.rfqId)}
                              className="confirm-submit-btn"
                            >
                              Confirm and Submit
                            </button>
                          </div>
                        );
                      }
                    }

                    //console.log("No matching condition. Rendering nothing.");
                    return null; // Fallback if no condition matches
                  })()}
                </div>
              </div>
            );
          })
        ) : /*AZIENDA VIEW*/
        isAzienda ? (
          <>
            <table className="rfq-table">
              <thead>
                <tr>
                  <th style={{ padding: "10px" }}>Incentivo</th>
                  <th style={{ padding: "10px" }}>PIVA</th>
                  <th style={{ padding: "10px" }}>Data</th>
                  <th style={{ padding: "10px" }}>Status dei Partner</th>
                </tr>
              </thead>
              <tbody>
                {rfqs.map((rfq) => (
                  <tr key={rfq.id}>
                    <td style={{ padding: "10px", textAlign: "left" }}>{rfq.link}</td>
                    <td style={{ padding: "10px", textAlign: "left"  }}>{rfq.piva}</td>
                    <td style={{ padding: "10px", textAlign: "left"  }}>
                      {new Date(rfq.timestamp.seconds * 1000).toLocaleString()}
                    </td>

                    <td style={{ padding: "10px", textAlign: "left"  }}>
                    {Array.isArray(rfq.partners) ? (
                      <p style={{margin: "2px"}}>
                        <strong>Inviato / Ricevuto:</strong>{" "}
                        {
                          rfq.partners.filter(
                            (partner) => (partner.UF > 0)
                          ).length
                        }{" "}/ {rfq.partners.length}
                      </p>
                    ) : (
                      <div>No partners available</div>
                    )}
                    </td>
                    <td>
                      <button
                        className="logout-button"
                        onClick={() => handleOpenRFQ(rfq)}
                      >
                        Dettagli
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* RFQ Modal */}
            {selectedRFQ && (
              <div className="rfq-modal">
                <div
                  className="rfq-content"
                  style={{
                    backgroundColor: "white",
                    padding: "20px",
                    borderRadius: "8px",
                  }}
                >
                  <h2 className="rfq-title">RICHIESTA DI PREVENTIVO</h2>
                  <p>
                    <strong>Nome dell'Azienda:</strong> Incentivato © (Capital AI
                    Srl)
                  </p>
                  <p>
                    <strong>Indirizzo dell'Azienda:</strong> Via Giuseppe Ripamonti 19
                  </p>
                  <p>
                    <strong>Telefono dell'Azienda:</strong> 02 8128 2670
                  </p>
                  <p>
                    <strong>Email dell'Azienda:</strong> info@incentivato.it
                  </p>
                  <hr />
                  <p>
                    <strong>Partita IVA:</strong> {selectedRFQ.piva}
                  </p>
                  <p>
                    <strong>Incentivo:</strong> {selectedRFQ.link}
                  </p>
                  <p>
                    <strong>Data:</strong>{" "}
                    {new Date(
                      selectedRFQ.timestamp.seconds * 1000
                    ).toLocaleString()}
                  </p>
                  <hr />
                  <h3>Preventivi</h3>
                  <table className="partner-table">
                    <thead>
                      <tr>
                        <th style={{ padding: "10px" }}>Partner</th>
                        <th style={{ padding: "10px" }}>In Anticipo</th>
                        <th style={{ padding: "10px" }}>Success Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedRFQ.partners &&
                      selectedRFQ.partners.length > 0 ? (
                        selectedRFQ.partners.map((partner, idx) => (
                          <tr key={idx}>
                            <td style={{ padding: "10px" }}>
                              <a
                                href={`/partnerview/${partner.firstName.toLowerCase()}/${
                                  partner.partnerId
                                }`}
                              >
                                {partner.firstName}
                              </a>
                            </td>

                            <td style={{ padding: "10px" }}>
                              {partner.UF ? `€${partner.UF}` : partner.UF}
                            </td>
                            <td style={{ padding: "10px" }}>{partner.SF}</td>
                            <td style={{ padding: "10px" }}>
                              <input
                                type="checkbox"
                                onChange={() =>
                                  handleConfirmPartner(
                                    selectedRFQ.id,
                                    partner.partnerId,
                                    partner.firstName
                                  )
                                }
                              />{" "}
                              Confermare
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">Nessun preventivo disponibile</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <hr />
                  <div
                    style={{
                      fontSize: "12px",
                      margin: "10px",
                      fontStyle: "italic",
                    }}
                  >
                    Capital AI facilita la ricerca di partner qualificati per
                    aziende interessate a ricevere preventivi su specifici
                    servizi. Il Cliente richiede un preventivo tramite Capital
                    AI, che individua i partner idonei, mentre la responsabilità
                    dell’erogazione del servizio resta interamente a carico del
                    partner selezionato. Capital AI può richiedere una quota di
                    avvio per la gestione della ricerca, mentre il pagamento del
                    servizio avverrà direttamente tra il Cliente e il partner.
                    Capital AI fornisce supporto ai partner ma non interviene
                    nell’esecuzione del servizio né è responsabile per eventuali
                    inadempienze. Il Cliente può revocare la richiesta in
                    qualsiasi momento, ma eventuali costi sostenuti non saranno
                    rimborsabili. L’accordo è regolato dalla legge italiana e
                    qualsiasi controversia sarà di competenza del Foro indicato.
                    Accettando questo accordo, il Cliente conferma di aver
                    compreso e accettato i termini...
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowContract(true);
                      }}
                    >
                      leggi di più
                    </a>
                    .
                  </div>
                  <>
                    {/* Modal Popup */}
                    {showContract && (
                      <div className="modal-overlay">
                        <div className="modalrfq-content">
                          <button
                            className="close-btn"
                            onClick={() => setShowContract(false)}
                          >
                            ✖
                          </button>
                          <PartnerContratto />
                        </div>
                      </div>
                    )}
                  </>
                  <hr />
                  <button className="logout-button" onClick={handleCloseRFQ}>
                    X
                  </button>
                </div>
              </div>
            )}
          </>
        ) : isAssoc ? (
          /* 🔥 Commercialista View */
          <table className="rfq-table">
            <thead>
              <tr>
                <th>Incentivo</th>
                <th>PIVA</th>
                <th>Data</th>
                <th>Partners</th>
              </tr>
            </thead>
            <tbody>
              {rfqs.map((rfq) => (
                <tr key={rfq.id}>
                  <td>{rfq.link}</td>
                  <td>{rfq.piva}</td>
                  <td>
                    {new Date(rfq.timestamp.seconds * 1000).toLocaleString()}
                  </td>
                  <td>
                    {Array.isArray(rfq.partners) ? (
                      rfq.partners.map((partner, idx) => (
                        <div key={idx}>
                          Partner ID: {partner.partnerId} | SF:{" "}
                          {partner.SF || "in attesa"} | UF:{" "}
                          {partner.UF || "in attesa"}
                        </div>
                      ))
                    ) : (
                      <div>No partners available</div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No RFQs available</div>
        )}
      </div>
    </div>
  );
};

export default MyRFQPage;

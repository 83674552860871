/* global gtag */

import React, { useState, useEffect } from 'react';

const Personalizza = () => {
  const [analyticsCookies, setAnalyticsCookies] = useState(false);
  const [marketingCookies, setMarketingCookies] = useState(false);

  // Load preferences on component mount
  useEffect(() => {
    const savedAnalytics = localStorage.getItem('analyticsCookies') === 'true';
    const savedMarketing = localStorage.getItem('marketingCookies') === 'true';
    setAnalyticsCookies(savedAnalytics);
    setMarketingCookies(savedMarketing);
  }, []);

  // Save preferences to localStorage and update gtag consent
  const savePreferences = () => {
    localStorage.setItem('analyticsCookies', analyticsCookies);
    localStorage.setItem('marketingCookies', marketingCookies);
  
    // Determine cookieConsent value
    let consent;
    if (analyticsCookies && marketingCookies) {
      consent = 'all';
    } else if (analyticsCookies) {
      consent = 'analytics_only';
    } else if (marketingCookies) {
      consent = 'marketing_only';
    } else {
      consent = 'necessary';
    }
  
    localStorage.setItem('cookieConsent', consent);
  
    // Update gtag consent state
    if (typeof gtag === 'function') {
      gtag('consent', 'update', {
        'ad_storage': marketingCookies ? 'granted' : 'denied',
        'analytics_storage': analyticsCookies ? 'granted' : 'denied',
      });
  
      // Track consent update as a custom event
      gtag('event', 'consent_update', {
        consent_status: consent,
        analytics_granted: analyticsCookies ? 'true' : 'false',
        marketing_granted: marketingCookies ? 'true' : 'false',
        event_category: 'consent',
        event_label: 'User updated cookie preferences',
        debug_mode: true, // Ensure it appears in DebugView
      });
    } else {
      console.warn('gtag is not available');
    }
  
    alert('Le preferenze sono state salvate!');
    window.location.href = '/';
  };
  
  
  

  // Reset preferences or close page
  const resetPreferences = () => {
    alert('Modifiche annullate.');
    window.location.href = '/';
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Personalizza le Preferenze dei Cookie</h1>
      <p style={styles.description}>
        Scegli quali tipi di cookie vuoi abilitare. Puoi modificare queste impostazioni in qualsiasi momento.
      </p>
      
      <div style={styles.checkboxGroup}>
        <label style={styles.label}>
          <input
            type="checkbox"
            disabled
            checked
            style={styles.checkbox}
          />
          Cookie Necessari (sempre abilitati)
        </label>
        <label style={styles.label}>
          <input
            type="checkbox"
            checked={analyticsCookies}
            onChange={(e) => setAnalyticsCookies(e.target.checked)}
            style={styles.checkbox}
          />
          Cookie Analitici (es. Google Analytics)
        </label>
        <label style={styles.label}>
          <input
            type="checkbox"
            checked={marketingCookies}
            onChange={(e) => setMarketingCookies(e.target.checked)}
            style={styles.checkbox}
          />
          Cookie di Marketing
        </label>
      </div>
      
      <div style={styles.buttonGroup}>
        <button style={styles.secondaryButton} onClick={resetPreferences}>
          Annulla
        </button>
        <button style={styles.primaryButton} onClick={savePreferences}>
          Salva Preferenze
        </button>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '600px',
    margin: '20px auto',
    padding: '20px',
    backgroundColor: '#fff',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    fontFamily: 'Roboto, sans-serif',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    color: '#343a40',
  },
  description: {
    color: '#6c757d',
    lineHeight: '1.5',
    textAlign: 'center',
  },
  checkboxGroup: {
    margin: '20px 0',
  },
  label: {
    display: 'block',
    marginBottom: '10px',
    fontSize: '16px',
    color: '#343a40',
  },
  checkbox: {
    marginRight: '10px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  primaryButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '14px',
  },
  secondaryButton: {
    backgroundColor: '#6c757d',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
    fontSize: '14px',
  },
};

export default Personalizza;

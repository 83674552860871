import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom"; // Import useLocation and Link
import "./allCSS.css";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get current route

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Close sidebar on route change
  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]); // Runs whenever the path changes

  return (
    <>
      {/* Sidebar */}
      <div className={`sidebar1 ${isOpen ? "open" : ""}`}>
        <button className="close-btn" onClick={toggleSidebar}>
          ✖
        </button>
        <ul className="sidebar1-links">
        <li style={{ fontSize: "30px" }}> PRM</li>
        <li><Link to="/searchpros" onClick={() => setIsOpen(false)}>🔍 Ricerca</Link></li>
        <li><Link to="/proprofile" onClick={() => setIsOpen(false)}>📂 Portfolio</Link></li>
        <li><Link to="/page3" onClick={() => setIsOpen(false)}>📊 Analytics</Link></li>
        <li><Link to="/messages" onClick={() => setIsOpen(false)}>💬 Messaggi</Link></li>
        <li><Link to="/scouting" onClick={() => setIsOpen(false)}>🔎 Scouting</Link></li>
        <li><Link to="/myrfq" onClick={() => setIsOpen(false)}>📄 Preventivi</Link></li>
        <li><Link to="/page3" onClick={() => setIsOpen(false)}>📝 Domande</Link></li>
        <li><Link to="/folderstorage" onClick={() => setIsOpen(false)}>📁 Documenti</Link></li>
        <li><Link to="/page3" onClick={() => setIsOpen(false)}>🤝 Agent Support</Link></li>
        <li><span onClick={() => setIsOpen(false)}>⚙️ Jobs Running</span></li>
        </ul>
      </div>

      {/* Open Button */}
      {!isOpen && (
        <button className="open-btn" onClick={toggleSidebar}>
          ☰
        </button>
      )}
    </>
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getAuth,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  setDoc,
  doc,
} from "firebase/firestore";

import representativeImage from "../LogoConfisi.png";
import scoutingpic from "../Scouting.png";
import alertspic from "../Alerts.png";
import consulenzapic from "../Consulenza.png";
import formazionepic from "../Formazione.png";
import instapic from "../InstaBrandPic.png";
import "..//pages/allCSS.css";
import "..//App.css";

const Sportello = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    region: "",
    goals: "",
    piva: "",
  });
  const [selectedService, setSelectedService] = useState("");
  const [userId, setuserId] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const auth = getAuth();
  const [authError, setAuthError] = useState("");
  const [rfqEmail, setRfqEmail] = useState("");
  const [rfqPassword, setRfqPassword] = useState("");
  const [rfqLoginEmail, setRfqLoginEmail] = useState("");
  const [rfqLoginPassword, setRfqLoginPassword] = useState("");
  const [showLoginPopup, setShowLoginPopup] = useState("");
  const [formVisible, setFormVisible] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [rfqRange, setRfqRange] = useState("");
  const [trackingParams, setTrackingParams] = useState({
    userId: "",
    piva: "",
    link: "",
  });

  const navigate = useNavigate();
  const location = useLocation();
  const db = getFirestore();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userId = params.get("userId");
    const piva = params.get("piva");
    const link = params.get("link");

    if (userId && piva && link) {
      try {
        const decodedLink = decodeURIComponent(link);
        const urlSegments = decodedLink.split("/").filter(Boolean); // Split URL into parts
        const slug = urlSegments[urlSegments.length - 1]; // Get the last segment

        setTrackingParams({
          userId,
          piva,
          link: slug, // Store only the slug
        });
      } catch (error) {
        console.error("Error parsing link:", error);
      }
    }
  }, [location.search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleServiceClick = (service) => {
    setSelectedService(service);
    setFormVisible(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const aggregatedData = `
      Nome: ${formData.name}
      Piva: ${formData.piva}
      Regione: ${formData.region}
      Goals: ${formData.goals}
      Servizio selezionato: ${selectedService}
    `.trim();

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/contactusConfisi`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          sender_email: formData.email,
          incentive_id: aggregatedData,
        }),
        credentials: "include",
      });

      const result = await response.json();

      if (response.ok) {
        setSubmissionStatus("E-mail inviata con successo.");
        setTimeout(() => {
          navigate("/");
          window.scrollTo(0, 0);
        }, 2000);
      } else {
        setSubmissionStatus(result.error || "An error occurred.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      setSubmissionStatus("Failed to send email. Please try again later.");
    }
  };

  const handleRfqClickSignup = async () => {
    if (!rfqEmail || !rfqPassword || !/^\S+@\S+\.\S+$/.test(rfqEmail)) {
      alert("Per favore inserisci una email e una password valida.");
      return;
    }

    setIsProcessing(true);
    setAuthError("");

    try {
      //console.log("🆕 Creating new user...");
      const newUser = await createUserWithEmailAndPassword(
        auth,
        rfqEmail,
        rfqPassword
      );
      const userId = newUser.user.uid;
      //console.log(`✅ New user created: ${userId}`);

      // 🔹 Send Email Verification
      await sendEmailVerification(newUser.user);
      //console.log("📧 Verification email sent.");

      // 🔹 Store User in Firestore
      await setDoc(doc(db, "users", userId), {
        email: rfqEmail,
        createdAt: new Date(),
        role: "azienda",
        piva: trackingParams.piva,
      });

      //console.log("✅ User details stored in Firestore.");

      // Submit RFQ after signup
      await submitRfq(userId, rfqEmail);
    } catch (error) {
      console.error("❌ Error creating user:", error);
      if (error.code === "auth/email-already-in-use") {
        console.log(
          "⚠️ Email già registrata. Chiedere all'utente di accedere."
        );
        setAuthError("Email già registrata. Prova ad accedere.");
      } else {
        setAuthError("Errore durante la registrazione. Riprova.");
      }
    } finally {
      setIsProcessing(false);
    }
  };

  /**
   * 🔐 **Handles Login + RFQ Submission**
   */
  const handleRfqClickLogin = async () => {
    if (
      !rfqLoginEmail ||
      !rfqLoginPassword ||
      !/^\S+@\S+\.\S+$/.test(rfqLoginEmail)
    ) {
      alert("Per favore inserisci una email e una password valida.");
      return;
    }

    setIsProcessing(true);
    setAuthError("");

    try {
      //console.log("🔑 Attempting login...");
      const existingUser = await signInWithEmailAndPassword(
        auth,
        rfqLoginEmail,
        rfqLoginPassword
      );
      const userId = existingUser.user.uid;
      //console.log(`✅ User logged in successfully: ${userId}`);

      // Submit RFQ after login
      await submitRfq(userId, rfqLoginEmail);
    } catch (error) {
      console.error("❌ Login failed:", error);
      setAuthError("Credenziali non valide. Riprova.");
    } finally {
      setIsProcessing(false);
    }
  };

  /**
   * 📩 **Handles RFQ Submission to Firestore + Flask**
   */
  const submitRfq = async (userId, email) => {
    try {
      //console.log(`ℹ️ Proceeding with RFQ submission for userId: ${userId}`);

      // ✅ 1. Store RFQ in Firestore
      const docRef = await addDoc(collection(db, "rfq"), {
        userId,
        email: email.trim(),
        piva: trackingParams.piva,
        link: trackingParams.link,
        range: rfqRange.trim(),
        timestamp: new Date(),
        partners: [],
      });
      //console.log("📂 RFQ successfully written to Firestore with ID:", docRef.id);

      // ✅ 2. Send RFQ request to Flask backend
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

      const response = await fetch(`${API_BASE_URL}/api/rfq`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          rfqDocId: docRef.id,
          userId,
          email: email.trim(),
          piva: trackingParams.piva,
          link: trackingParams.link,
        }),
      });

      if (!response.ok) {
        const result = await response.json();
        console.error("❌ RFQ API Error:", result);
        alert(
          `Errore nel server: ${result.error || "Si è verificato un errore."}`
        );
        return;
      }

      //console.log("✅ RFQ successfully sent to Flask backend!");

      // ✅ Close the popup and reset
      setTimeout(() => {
        setShowPopup(false);
        setIsRfqVisible(false);
        //console.log("🪄 Popup closed!");
      }, 500);

      alert("Grazie! Ti contatteremo presto.");
      setRfqEmail("");
      setRfqPassword("");
      setRfqLoginEmail("");
      setRfqLoginPassword("");
      setRfqRange("");
    } catch (error) {
      console.error("❌ Error submitting RFQ:", error);
      setAuthError("Errore durante l'invio della richiesta. Riprova.");
    }
  };

  const [isRfqVisible, setIsRfqVisible] = useState(false);

  useEffect(() => {
    if (trackingParams.userId && trackingParams.piva && trackingParams.link) {
      // Trigger the slide-in effect
      setTimeout(() => setIsRfqVisible(true), 100); // Small delay to allow mounting
    }
  }, [trackingParams]);

  /////////////////JSX

  return (
    <div className="partner-container">
      <div className="hero-section">
        <div className="text-section">
          <h1>CONFISI e Incentivato</h1>
          <p className="intro-text">
            Incentivato.it e CONFISI collaborano per offrire servizi esclusivi
            ai membri di CONFISI nel campo della finanza agevolata.
          </p>
          <p className="intro-text">
            La partnership prevede supporto personalizzato, webinar e
            masterclass, con promozione tramite i canali CONFISI
          </p>
          <p className="intro-text">
            Per iniziare, scegli un servizio qui sotto e compila il modulo per
            richiederlo.
          </p>

          {trackingParams.userId &&
            trackingParams.piva &&
            trackingParams.link && (
              <div
                className={`rfq-button-container ${
                  isRfqVisible ? "active" : ""
                }`}
              >
                <h1 className="rfq-heading" style={{color: "white"}}>Richiedi Preventivo</h1>
                <div className="rfq-box">
                  <div style={{marginBottom:"30px"}}>
                  <h3>
                    <strong>P.IVA:</strong> {trackingParams.piva}
                  </h3>
                  <h3>
                    <strong>Incentivo:</strong> {trackingParams.link}
                  </h3>
                  <h3> <strong>Importo Contributo Richiesto (€)</strong></h3>
                      <input
                          type="text"
                          placeholder="Inserisci l'importo richiesto"
                          value={rfqRange}
                          onChange={(e) => setRfqRange(e.target.value)}
                          className="rfq-range-input"
                          required
                      />
                      </div>
                      <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
  
  {/* New User Signup Section */}
  <div style={{ flex: 1 }}>
    <h3 style={{ color: "#FF5758", marginBottom: "10px" }}>Nuovo utente?</h3>
    <input
      type="email"
      placeholder="Inserisci la tua email"
      value={rfqEmail}
      onChange={(e) => setRfqEmail(e.target.value)}
      className="rfq-email-input"
      required
      style={{ width: "100%", marginBottom: "10px" }}
    />
    <input
      type="password"
      placeholder="Inserisci la password"
      value={rfqPassword}
      onChange={(e) => setRfqPassword(e.target.value)}
      className="rfq-password-input"
      required
      style={{ width: "100%", marginBottom: "10px" }}
    />
    <button className="rfq-button" onClick={handleRfqClickSignup} style={{ width: "100%" }}>
      Registrati e Invia Richiesta
    </button>
  </div>

  <hr style={{ border: "1px solid #ddd", height: "auto" }} />

  {/* Existing User Login Section */}
  <div style={{ flex: 1 }}>
    <h3 style={{ color: "#FF5758", marginBottom: "10px" }}>Hai già un account?</h3>
    <input
      type="email"
      placeholder="Email"
      value={rfqLoginEmail}
      onChange={(e) => setRfqLoginEmail(e.target.value)}
      className="rfq-email-input"
      required
      style={{ width: "100%", marginBottom: "10px" }}
    />
    <input
      type="password"
      placeholder="Password"
      value={rfqLoginPassword}
      onChange={(e) => setRfqLoginPassword(e.target.value)}
      className="rfq-password-input"
      required
      style={{ width: "100%", marginBottom: "10px" }}
    />
    <button className="rfq-button" onClick={handleRfqClickLogin} style={{ width: "100%" }}>
      Accedi e Invia Richiesta
    </button>
  </div>

</div>

{/* Display error message if any */}
{authError && <p className="error-message" style={{ marginTop: "10px", textAlign: "center" }}>{authError}</p>}

                </div>
              </div>
            )}
        </div>
        <div className="image-section">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "flex-start",
            }}
          >
            <img src={representativeImage} alt="Rappresentazione del Partner" />
            <img src={instapic} alt="Incentivato img" />
          </div>
        </div>
      </div>

      <div className="hero-section2">
        <div className="text-section2">
          <h1>Servizi di Supporto alle Imprese</h1>
        </div>
        <div className="image-section2">
          <img
            src={scoutingpic}
            alt="Scouting img"
            onClick={() => handleServiceClick("Scouting")}
          />
          <img
            src={alertspic}
            alt="Alerts img"
            onClick={() => handleServiceClick("Alerts")}
          />
          <img
            src={consulenzapic}
            alt="Consulenza img"
            onClick={() => handleServiceClick("Consulenza")}
          />
          <img
            src={formazionepic}
            alt="Formazione img"
            onClick={() => handleServiceClick("Formazione")}
          />
        </div>
      </div>

      {formVisible && (
        <div className="form-section">
          <h2>
            Hai scelto:{" "}
            <span style={{ color: "#FF5758" }}>{selectedService}</span> -
            Fantastico! Ci siamo quasi!
          </h2>
          <p>Aggiungi qualche dettaglio e verrà contattata/o subito.</p>
          <form onSubmit={handleSubmit} className="confisi-form">
            <label>
              Nome:
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Nome Cognome"
                required
              />
            </label>
            <label>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Nome@mail.it"
                required
              />
            </label>
            <label>
              Partita Iva:
              <input
                type="text"
                name="piva"
                value={formData.piva}
                onChange={handleChange}
                placeholder="IT123456789"
                required
              />
            </label>
            <label>
              Regione:
              <input
                type="text"
                name="region"
                value={formData.region}
                onChange={handleChange}
                placeholder="Lombardia"
                required
              />
            </label>
            <label>
              Obiettivi:
              <textarea
                name="goals"
                value={formData.goals}
                onChange={handleChange}
                placeholder="Descrivi i vostri obiettivi per l'impresa"
                required
              />
            </label>
            <button type="submit">Invia</button>
          </form>
          {submissionStatus && (
            <p className="submission-status">{submissionStatus}</p>
          )}
        </div>
      )}

      {/* Popup */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>Grazie per la tua richiesta! Ti contatteremo presto.</p>
            <button onClick={() => setShowPopup(false)}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sportello;

import React, { useState } from 'react';

const RunCVButton = ({ userId, onCVProcessed }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const handleRunCV = async () => {
    setIsProcessing(true);
    setStatusMessage("Analizzando il CV...");

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/summarizecv`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId }),
      });

      const result = await response.json();

      if (response.ok) {
        setStatusMessage("CV elaborato con successo e salvato!");
        onCVProcessed(); // Notify parent that CV processing is done
      } else {
        setStatusMessage(`Errore: ${result.error}`);
      }
    } catch (error) {
      console.error("Errore durante l'elaborazione del CV:", error);
      setStatusMessage("Si è verificato un errore durante l'elaborazione del CV.");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div>
      <button className="upload-button" onClick={handleRunCV} disabled={isProcessing}>
        {isProcessing ? "Elaborazione in corso..." : "Analizza il mio CV 🤖"}
      </button>
      {statusMessage && <p>{statusMessage}</p>}
    </div>
  );
};

export default RunCVButton;

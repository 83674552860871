import React from 'react';

const PartnerContratto = () => {
    return (
        <div>
            <h1 style={{fontSize: '14px', padding: '20px'}}>
            ACCORDO PER LA RICHIESTA DI PREVENTIVO E COLLABORAZIONE CON PARTNER
            <hr />
<br />
Tra
<br />
<br />
Capital AI Srl, con sede legale in via Giuseppe Ripamonti 19, Milano 20135, P.IVA 13856590966, di seguito denominata “Capital AI”;
<br />
<br />
e
<br />
<br />
L'azienda che richiede il servizio, di seguito denominata “Business B”;
<br />
<br />
e
<br />
<br />
Il partner selezionato per fornire il servizio, di seguito denominato “Partner C”.
<br />
<hr />
Premesso che:
<br />
<br />
Capital AI facilita il processo di ricerca di partner qualificati per l’ottenimento di agevolazioni e contributi per imprese.
<br />
<br />
Business B desidera ricevere un preventivo e, in caso di accettazione, collaborare con un partner per l’erogazione del servizio richiesto;
<br />
<br />
Partner C sarà il soggetto responsabile della gestione e dell'esecuzione del servizio;
<br />
<br />
Capital AI non assume alcuna responsabilità diretta nell’esecuzione del servizio ma fornisce supporto a Partner C nella gestione del Cliente;
<br />
<hr />
Si conviene e stipula quanto segue:
<br />
<br />
1. Oggetto del Contratto
<br />
- 1.1 Capital AI individuerà uno o più partner qualificati per fornire un preventivo a Business B in relazione ai servizi richiesti.
<br />
- 1.2 Il preventivo sarà formulato direttamente dai partner selezionati, e sarà Business B a decidere se accettarlo e collaborare con un Partner C.
<br />
- 1.3 Il rapporto contrattuale per l’erogazione del servizio sarà esclusivamente tra Partner C e Business B.
<br />
- 1.4 Capital AI faciliterà il processo e fornirà supporto ai partner, senza intervenire direttamente nell’esecuzione del servizio.
<br />
<br />
2. Modalità di Pagamento e Success Fee
<br />
- 2.1 Business B riconosce che Capital AI potrà richiedere una quota di avvio per la gestione della ricerca dei partner.
<br />
- 2.2 Partner C e Business B concordano una success fee sul valore dell’incentivo ottenuto, che sarà regolata come segue:
<br />
Al momento della concessione dell’incentivo, Partner C emetterà fattura a Business B per un importo pari alla percentuale concordata sul totale del contributo ottenuto.
Ad esempio, se Business B ottiene un fondo perduto di €50.000 con una success fee del 7%, Partner C fatturerà €3.500 nel momento in cui l’incentivo è formalmente concesso.
In alcuni casi, Partner C e Business B potranno concordare un pagamento frazionato, ad esempio 50% alla concessione dell’incentivo e il restante 50% alla ricezione del contributo da parte di Business B.
Tale modalità di pagamento dovrà essere concordata preventivamente e per iscritto tra Partner C e Business B.
<br />
<br />
3. Responsabilità e Limitazioni
<br />
- 3.1 Business B riconosce che Capital AI opera esclusivamente come intermediario e non è parte dell’accordo tra Business B e Partner C.
<br />
- 3.2 Partner C è responsabile dell’erogazione del servizio e della gestione del rapporto con Business B.
<br />
- 3.3 Capital AI non potrà essere ritenuta responsabile per eventuali inadempienze, ritardi o difetti nell’erogazione del servizio da parte di Partner C.
<br />
- 3.4 Eventuali controversie relative all’erogazione del servizio e ai pagamenti dovranno essere risolte direttamente tra Partner C e Business B.
<br />
<br />
4. Durata e Recesso
<br />
- 4.1 Il presente accordo entra in vigore alla data di accettazione da parte di Business B e resta valido fino alla conclusione della fase di preventivazione e assegnazione dell’incarico a Partner C.
<br />
- 4.2 Business B può revocare la richiesta di preventivo in qualsiasi momento, fermo restando che eventuali costi già sostenuti da Capital AI per l’avvio del processo non saranno rimborsabili.
<br />
<br />
5. Legge Applicabile e Foro Competente
<br />
- 5.1 Il presente accordo è regolato dalla legge italiana.
<br />
- 5.2 Per qualsiasi controversia relativa all'interpretazione o esecuzione del presente accordo, sarà competente in via esclusiva il Foro di [Città].
<br />
<hr />
Accettando il presente accordo, Business B e Partner C confermano di aver letto, compreso e accettato tutti i termini sopra indicati.
<br />
                
            </h1>
        </div>
    );
};

export default PartnerContratto;

import React from "react";

const Page3 = () => {
    return (
        <div style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            backgroundColor: "#282c34",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "24px",
            fontFamily: "Arial, sans-serif"
        }}>
            Coming Soon...
        </div>
    );
};

export default Page3;
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Incentivato from '../../src/landing-pic.png'; // Replace with your actual image path

const TestPlayer = () => {
  const Player = ReactPlayer.default; // Use the explicit default export
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div style={{ position: 'relative', width: '100%', paddingTop: '56.25%' }}>
      {/* Video Placeholder and ReactPlayer */}
      <Player
        url="https://incentivato.it/Incentivato2113.mp4"
        playing={isPlaying}
        muted
        controls
        width="100%"
        height="100%"
        style={{ position: 'absolute', top: 0, left: 0 }}
        light={Incentivato} // Placeholder image
        playIcon={ // Custom center play button
          <button
            style={{
              background: 'rgba(0, 0, 0, 0.7)',
              border: 'none',
              borderRadius: '50%',
              width: '60px',
              height: '60px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={handlePlay} // Start video on button click
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="white"
              viewBox="0 0 24 24"
            >
              <path d="M8 5v14l11-7z" />
            </svg>
          </button>
        }
        onClickPreview={handlePlay} // Start playing when the preview is clicked
      />
    </div>
  );
};

export default TestPlayer;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import representativeImage from "../become_partner.png";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";

const DiventaPartner = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    profession: "",
    regione: "",
    services: "",
    message: "",
    role: "partner",
  });

  const [submissionStatus, setSubmissionStatus] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const db = getFirestore();

    if (formData.password.length < 6) {
      setSubmissionStatus("La password deve contenere almeno 6 caratteri.");
      return;
    }

    setIsLoading(true); // Start loading spinner

    const aggregatedData = `
    Nome: ${formData.firstName}
    Cognome: ${formData.lastName}
    Professione: ${formData.profession}
    Regione: ${formData.regione}
    Servizi Offerti: ${formData.services}
    Messaggio: ${formData.message}
  `.trim();

    try {
      // 1. Send Email (existing functionality)
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/contactus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sender_email: formData.email,
          incentive_id: aggregatedData,
        }),
        credentials: "include",
      });

      const result = await response.json();

      if (!response.ok) {
        setSubmissionStatus(
          result.error ||
            "Si è verificato un errore durante l'invio dell'email."
        );
        setIsLoading(false); // Stop loading spinner
        return;
      }

      // 2. Create User in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      // 3. Save User Data to Firestore (users collection)
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email.toLowerCase(), // Normalize email
        profession: formData.profession,
        regione: formData.regione,
        services: formData.services,
        message: formData.message,
        role: formData.role, // Storing role as "partner"
        createdAt: new Date(),
      });

      // 4. Send Registration Confirmation Email to User
      await fetch(`${API_BASE_URL}/api/send-registration-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          recipient_email: formData.email,
          firstName: formData.firstName,
        }),
        credentials: "include",
      });

      // 5. Confirm User is Authenticated Before Navigation
      onAuthStateChanged(auth, (currentUser) => {
        setIsLoading(false); // Stop loading spinner

        if (currentUser && currentUser.uid === user.uid) {
          setSubmissionStatus("Registrazione completata. Reindirizzamento...");
          setTimeout(() => {
            navigate("/partnerportal");
            window.scrollTo(0, 0);
          }, 500);
        } else {
          setSubmissionStatus("Errore durante l'autenticazione. Riprova.");
        }
      });
    } catch (error) {
      console.error("Error during signup:", error);

      // Handle Firebase Auth errors
      if (error.code === "auth/email-already-in-use") {
        setSubmissionStatus(
          "Questo indirizzo email è già registrato. Effettua il login o usa un'email diversa."
        );
      } else if (error.code === "auth/invalid-email") {
        setSubmissionStatus("L'email inserita non è valida.");
      } else if (error.code === "auth/weak-password") {
        setSubmissionStatus(
          "La password è troppo debole. Deve contenere almeno 6 caratteri."
        );
      } else {
        setSubmissionStatus(
          error.message || "Si è verificato un errore imprevisto."
        );
        setIsLoading(false); // Stop loading spinner
      }
    }
  };

  return (
    <div className="partner-container">
      <div className="hero-section">
        <div className="text-section">
          <h1>Espandi il Tuo Business con Incentivato</h1>
          <p className="intro-text">
            Diventa un partner di fiducia e accedi alle migliori opportunità di
            finanza agevolata. Siamo alla ricerca di consulenti, commercialisti,
            e professionisti certificati per unirsi alla nostra rete e
            supportare le PMI nel loro percorso di crescita.
          </p>
          <p className="cta-message">
            Compila il modulo qui sotto per unirti alla nostra rete di
            professionisti.
          </p>
        </div>
        <div className="image-section">
          <img src={representativeImage} alt="Rappresentazione del Partner" />
        </div>
      </div>

      <div className="form-section">
        <h2>Diventa Partner</h2>
        <p>
          Se sei un{" "}
          <span className="highlight">consulente in finanza agevolata</span>,{" "}
          <span className="highlight">commercialista</span>,{" "}
          <span className="highlight">notaio</span> o un{" "}
          <span className="highlight">professionista certificato</span>, compila
          il modulo per proporre le tue competenze e diventare un partner
          selezionato.
        </p>
        {isLoading ? (
  <div className="spinner-container">
    <div className="spinner"></div>
    <p>Processing...</p>
  </div>
) : (
        <form onSubmit={handleSubmit} className="partner-form">
          <label>
            Nome:
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="Nome"
              required
            />
          </label>

          <label>
            Cognome:
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Cognome"
              required
            />
          </label>

          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              required
            />
          </label>

          <label>
            Password:
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Crea una password"
              required
              minLength={6}
            />
          </label>

          <label>
            Professione:
            <select
              name="profession"
              value={formData.profession}
              onChange={handleChange}
              required
            >
              <option value="">Seleziona la tua professione</option>
              <option value="consulente">
                Consulente in Finanza Agevolata
              </option>
              <option value="commercialista">Commercialista</option>
              <option value="notaio">Notaio</option>
              <option value="altro">Altro</option>
            </select>
          </label>

          <label>
            Regione:
            <input
              type="text"
              name="regione"
              value={formData.regione}
              onChange={handleChange}
              placeholder="Lombardia"
              required
            />
          </label>

          <label>
            Servizi Offerti:
            <textarea
              name="services"
              value={formData.services}
              onChange={handleChange}
              placeholder="Descrivi i servizi che offri"
              required
            />
          </label>

          {/*   <label>Messaggio:
    <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Inserisci un messaggio facoltativo" />
  </label> */}

          <button type="submit">Invia la tua candidatura</button>
        </form>
)}
        {submissionStatus && (
          <p className="submission-status">{submissionStatus}</p>
        )}
      </div>
    </div>
  );
};

export default DiventaPartner;

import React, { useEffect } from "react";

const ThankYou = () => {
  useEffect(() => {
    // Track the conversion in Google Ads
    if (typeof window.gtag === "function") {
      window.gtag("event", "conversion", {
        send_to: "AW-11519818896/book_demo", // Replace XXXXX with your Conversion Label
        event_category: "engagement",
        event_label: "Thank You Page",
      });
    }
  }, []);

  useEffect(() => {
    // Redirect to Calendly after 2 seconds
    const timer = setTimeout(() => {
      window.location.href = "https://calendly.com/vincent-capital-ai/30min?month=2025-01";
    }, 2000);

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Grazie per aver prenotato la demo!</h1>
      <p style={styles.description}>
        Stiamo reindirizzando alla pagina di prenotazione. Attendi un momento...
      </p>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f8f9fa",
    fontFamily: "Roboto, sans-serif",
  },
  title: {
    fontSize: "28px",
    color: "#343a40",
  },
  description: {
    fontSize: "18px",
    color: "#6c757d",
    marginTop: "10px",
  },
};

export default ThankYou;

import PartnerPortal from "../../src/pages/partnerportal.js";
import React, { useState, useEffect } from "react";
import { auth, googleProvider, db } from "../config/firebase.js";
import {
  getFirestore,
  query,
  where,
  doc,
  collection,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Users from "./users.js";
import { useAuth } from "../../src/components/auth-email.js";
import ToggleSwitch from "../components/toggle.js";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  LineChart,
} from "recharts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//../src/components/userPrompt.js";
import thumbup from "..//../src/thumbup.svg";
import thumbdown from "..//../src/thumbdown.svg";
import rightarrow from "..//../src/right_arrow.svg";
import { Spinner } from "react-bootstrap";
import { signOut } from "firebase/auth";
import AvatarSelector from ".//..//../src/components/avatar.js";
import Storage from ".//..//../src/pages/folderstorage.js";
import VendorCV from "./vendorCV.js";
import RunCVButton from "../components/runCVButton.js";
import IncentiveSelector from "../components/incentiveSelector.js";

const PartnerView = () => {
  const { currentUser } = useAuth(); // Using a custom hook to get the current user
  const [atecoL3, setAtecoL3] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyRole, setCompanyRole] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyComune, setCompanyComune] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyPiva, setCompanyPiva] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [currentUserID, setCurrentUserID] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState(0);
  const [trustPilot, setTrustPilot] = useState(0);
  const [summary, setSummary] = useState(0);
  const [years, setYears] = useState(0);
  const [cw, setCw] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [services, setServices] = useState([]);
  const [incentives, setIncentives] = useState([]);
  const [phone, setPhone] = useState("");
  const [logo, setLogo] = useState("");
  const { partnerId } = useParams(); // Get partnerId from URL

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      if (partnerId) {
        try {
          const userRef = doc(db, "users", partnerId);
          const userSnap = await getDoc(userRef);
  
          if (userSnap.exists()) {
            const userData = userSnap.data();
  
            // Set state variables with the partner's data
            setFirstName(userData.firstName || "N/A");
            setLastName(userData.lastName || "N/A");
            setCompanyEmail(userData.email || "N/A");
            setRegione(userData.regione || "N/A");
            setCompanyRole(userData.role || "N/A");
            setTrustPilot(userData.trustPilot || "N/A");
            setSummary(userData.summary || "N/A");
            setYears(userData.years || "N/A");
            setPhone(userData.phone || "N/A");
            setLogo(userData.logo || "");
            setCompanyPiva(userData.piva || "");
            setCompanyName(userData.companyName || "N/A");
            setCompanySize(userData.companySize || "N/A");
            setCompanyEmployees(userData.companyEmployees || "N/A");
            setSelectedAvatar(userData.avatar || "");
            setCompanyTurnover(userData.companyTurnover || 0);
            setServices(
              Array.isArray(userData.services) ? userData.services : []
            );
            
            // Fetch incentives with titles from incentivi collection
            if (Array.isArray(userData.incentives) && userData.incentives.length > 0) {
              const incentiveTitles = await Promise.all(
                userData.incentives.map(async (incentiveId) => {
                  const incentiveRef = doc(db, "incentivi", incentiveId);
                  const incentiveSnap = await getDoc(incentiveRef);
                  return incentiveSnap.exists() ? incentiveSnap.data().Titolo : "Unknown Incentive";
                })
              );
              setIncentives(incentiveTitles);
            } else {
              setIncentives([]);
            }
          } else {
            console.log("No user data found for partnerId:", partnerId);
          }
        } catch (error) {
          console.error("Error fetching partner data:", error);
        }
      }
    }
  
    fetchUserData();
  }, [partnerId]); // Dependency array updated to use partnerId

  const extractBase = (ateco) => {
    // Regex to capture two digits before and one or two digits after the decimal point
    const numericalPartMatch = ateco.match(/^(\d{2}\.\d{1,2})/);
    let numericalPart = "";

    if (numericalPartMatch) {
      numericalPart = numericalPartMatch[1];
      const decimalParts = numericalPart.split(".");

      // Ensure two decimal places by padding with a zero if necessary
      if (decimalParts[1].length === 1) {
        numericalPart = `${decimalParts[0]}.${decimalParts[1]}0`;
      }
    }

    return numericalPart;
  };

  const navigate = useNavigate();
  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (err) {
      console.error("Error logging out:", err);
    }
  };

  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [avatarSelected, setAvatarSelected] = useState(false);

  const [cvProcessed, setCVProcessed] = useState(false);
  const [showCV, setShowCV] = useState(true); // Initially showing the CV box
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userId = user.uid;
        setCurrentUserID(userId);

        try {
          const userRef = doc(db, "users", userId);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();

            // Check if 'summary' exists to determine if CV is processed
            if (userData.summary && userData.summary.trim() !== "") {
              setCVProcessed(true);
              setShowCV(false); // Hide CV box if processed
            } else {
              setCVProcessed(false); // Not processed yet
              setShowCV(true); // Show CV box for processing
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setLoading(false); // Stop loading
        }
      } else {
        console.log("No authenticated user found.");
        setLoading(false); // Stop loading even if no user
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, [auth, db]);

  // Called after successful CV processing
  const handleCVProcessed = () => {
    setCVProcessed(true);
    setShowCV(false);
  };

  // Toggle visibility of CV box
  const toggleCVVisibility = () => {
    setShowCV((prev) => !prev);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="searchy-container">
      {!isAuthenticated && <UserPromptModal />}

      <div className="searchy-containered" style={{ background: "black" }}>
        <div>
          <div className="agent2-containers">
            <div
              className="incentivy-container"
              style={{ background: "black" }}
            >
              {/* Check if the user is a partner */}

              <div>
                <div>
                  <div className="avatar-confirmation">
                    <h3 style={{ color: "white" }}>
                      {firstName}
                    </h3>
                    <img
  src={logo || selectedAvatar} // Use logo (PNG from avatar field) if available, else fallback to selectedAvatar
  alt="Selected Avatar"
  className="selected-avatar-previewRFQ"
/>
                  </div>
                </div>
                <div className="centered-cvbox">
                  {/* Toggle Button to Show/Hide CV */}
                  {cvProcessed && (
                    <button
                      className="toggle-button"
                      onClick={toggleCVVisibility}
                    >
                      {showCV ? "Nascondi CV 📁" : "Mostra CV 📁"}
                    </button>
                  )}

                  {/* CV Box and RunCVButton (Hidden After Processing) */}
                </div>

                {logo && (
                  <div style={{ textAlign: "center", margin: "20px 0" }}>
                    <img
                      src={logo}
                      alt={`${firstName} ${lastName} Logo`}
                      style={{
                        maxWidth: "200px",
                        height: "auto",
                        borderRadius: "8px",
                        border: "2px solid white",
                      }}
                    />
                  </div>
                )}
                <div className="profile-container">
                  <div className="profile-card">
                    <h2 className="profile-heading">Profilo</h2>

                    {/* Role */}
                    <div className="profile-field">
                      <div className="field-label">Ruolo</div>
                      <div className="field-content">{companyRole}</div>
                    </div>

                    {/* Regione */}
                    <div className="profile-field">
                      <div className="field-label">Regione</div>
                      <div className="field-content">{regione}</div>
                    </div>

                    {/* Years */}
                    <div className="profile-field">
                      <div className="field-label">Anni</div>
                      <div className="field-content">{years}</div>
                    </div>

                    {/* Summary */}
                    <div className="profile-field summary-box">
                      <div className="field-label rotated-label">Sintesi</div>
                      <div className="field-content">{summary}</div>
                    </div>

                    {/* Services */}
                    <div className="profile-field">
                      <div className="field-label">Servizi</div>
                      <div className="services-container">
                        {Array.isArray(services) && services.length > 0 ? (
                          services.map((service, index) => (
                            <div key={index} className="incentive-box">
                              {service}
                            </div>
                          ))
                        ) : (
                          <p className="no-services">No services listed.</p>
                        )}
                      </div>
                    </div>

                    {/* TrustPilot */}
                    <div className="profile-field">
                      <div className="field-label">TrustPilot</div>
                      <div className="field-content">{trustPilot || "N/A"}</div>
                    </div>

                    {/* Incentives */}
                    <div className="profile-field incentives-box">
                      <div className="field-label">Incentivi</div>
                      <div className="field-content">
                      {Array.isArray(incentives) && incentives.length > 0 ? (
  incentives.map((incentive, index) => (
    <div key={index} className="incentive-box">
      {incentive}
    </div>
  ))
) : (
  <p className="no-services">No services listed.</p>
)}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerView;

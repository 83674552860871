///imports
import React, {
    useEffect,
    useState,
    useRef,
  } from "react";
  import { db, auth } from "..//..//src/config/firebase.js";
  import {
    getDocs,
    getDoc,
    arrayRemove,
    collection,
    where,
    query,
  } from "firebase/firestore";
  import { getAuth, onAuthStateChanged } from "firebase/auth";
  import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
    Navigate,
    useLocation,
  } from "react-router-dom";
  import "..//pages/allCSS.css";
  import UserPromptModal from "..//..//src/components/userPrompt.js";
  //import LongPollDisplay from '..//components/LongPolling.js';
  import { useAuth } from "../../src/components/auth-email.js";
  import psuimg from "../../src/postsignupimg2.png";
  import "./homechat.css";
  
  function PostSignupNote() {
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    const [role, setRole] = useState("");
    const navigate = useNavigate();
    const headerStyles = {
      width: "100%",
      backgroundColor: "black",
      color: "white",
      padding: "10px",
      fontFamily: "'Poppins', sans-serif",
      textAlign: "center",
      wordSpacing: "6px",
      fontSize: "35px",
      //animation: "flash 3s infinite",
    };



 /*  gtag('event', 'conversion_event_signup', {
    // <event_parameters>
  }); */


  
    ////////////////////////////////////////////////////////////////////////////////////
    //text entry to fb db
   
    const location = useLocation();
    const [currentUser, setCurrentUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedRole, setSelectedRole] = useState("");
    const [isAuthenticated, setIsAuthenticated] = useState(false);
  
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        setCurrentUser(user);
        setIsAuthenticated(!!user);
        if (user) {
          //console.log("User authenticated with email:", user.email);
          fetchUserData(user.email); // Directly pass the email here
        }
      });
      return () => unsubscribe();
    }, []);

    const fetchUserData = async (email) => {
      if (email) {
        try {
          const usersCollectionRef = collection(db, "users");
          const q = query(usersCollectionRef, where("email", "==", email));
          const querySnapshot = await getDocs(q);
  
          if (!querySnapshot.empty) {
            const userData = querySnapshot.docs[0].data();
            //console.log("User data found:", userData);
            setSelectedRole(userData.role);
          } else {
            console.log("No user data found for email:", email);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };
   
    const handleNavigate = () => {
      setShowModal(false); // Hide modal
      if (selectedRole) {
        //console.log("Navigating based on role:", selectedRole); // Log role before navigation
        if (selectedRole === "azienda") {
          //navigate("/search"); // Navigate to azienda strategy page
          navigate("/goals/asst_z07YzNlfWW5LFE2not8HmSZ2/"); 
        } else if (selectedRole === "commercialista") {
          navigate("/searchpros"); // Navigate to commercialista page
        } else {
          console.log("Unexpected role:", selectedRole);
        }
      } else {
        console.log("Role is not set yet, navigation blocked");
      }
    };

    ///////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////start of JSX
  
    return (
      <div className="searchy-container">
        {!isAuthenticated && <UserPromptModal />}
        <div className="postnote-container">
          <div
            style={{
              position: "relative",
              textAlign: "left",
              border: "none",
              marginRight: "30px",
              marginTop: "10px",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
                lineHeight: "3.5rem"
              }}
            >
              {" "}
              🎉 Benvenuto su Incentivato!
            </h1>
            <div className="image-psu" style={{ cursor: "pointer" }}>
              <img src={psuimg} alt=" " />
            </div>
            <h2
              style={{
                fontSize: "25px",
                paddingTop: "25px",
                lineHeight: "2.5rem",
              }}
            >
              Siamo entusiasti di averti con noi e di
              supportarti nelle tue opzioni di finanziamento.
              Richiedere sovvenzioni spesso può farti sentire come se fossi ai piedi della montagna, 
              ma siamo certi che con Incentivato sventolerai presto la bandiera degli incentivi.
              <br />
              <br />
              Sei attualmente iscritto al livello base ma puoi effettuare l'aggiornamento in qualsiasi 
              momento per ottenere consigli sugli incentivi più personalizzati e completare le richieste.
              <br />
              <br />
              Sei pronto? 🚀 🚀 🚀
            </h2>
            <div>
              <div>
                <div>
                  <div style={{ margin: "50px" }}></div>
                  {!isAuthenticated && <UserPromptModal />}
                  <div
                    style={{
                      margin: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  ></div>
                </div>
                <div className="App">               
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "30px",
                      height: "50px"
                    }}
                  >
                   <button className="logout-button" onClick={handleNavigate}>
                   Procedi
                 </button>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default PostSignupNote;
  
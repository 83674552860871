import React, { useState, useEffect } from 'react';
import { getFirestore, doc, updateDoc, collection, getDocs } from 'firebase/firestore';
import './placeholder.css'; // Ensure CSS is applied

const IncentiveSelector = ({ userId, existingIncentives = [] }) => {
  const [allIncentives, setAllIncentives] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [filteredIncentives, setFilteredIncentives] = useState([]);
  const [selectedIncentives, setSelectedIncentives] = useState(existingIncentives);
  const [isSaving, setIsSaving] = useState(false);


  const db = getFirestore();

  // Fetch all incentives from Firestore
  useEffect(() => {
    const fetchIncentivesData = async () => {
      try {
        const incentivesCollectionRef = collection(db, 'incentivi');
        const querySnapshot = await getDocs(incentivesCollectionRef);

        const incentivesData = [];
        querySnapshot.forEach((doc) => {
          incentivesData.push({
            ...doc.data(),
            ID_Incentivo: doc.id,
          });
        });
        setAllIncentives(incentivesData);
      } catch (error) {
        console.error('Error fetching incentives:', error);
      }
    };

    fetchIncentivesData();
  }, [db]);

  // Handle search input change and filter incentives
  const handleSearchChange = (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);

    if (keyword.trim() === '') {
      setFilteredIncentives([]);
    } else {
      const filtered = allIncentives.filter((incentive) =>
        incentive.Titolo?.toLowerCase().includes(keyword.toLowerCase())
      );
      setFilteredIncentives(filtered.slice(0, 10)); // Limit to 10 suggestions
    }
  };

  // Add incentive to selected list
  const handleIncentiveSelect = (incentive) => {
    if (!selectedIncentives.includes(incentive.ID_Incentivo)) {
      setSelectedIncentives((prev) => [...prev, incentive.ID_Incentivo]);
    } else {
      alert('Incentive already selected.');
    }
    setSearchKeyword('');
    setFilteredIncentives([]);
  };

  // Remove incentive from selected list
  const removeSelectedIncentive = (incentiveId) => {
    setSelectedIncentives((prevSelected) =>
      prevSelected.filter((id) => id !== incentiveId)
    );
  };

  // Save selected incentives to Firestore
  const saveSelectedIncentives = async () => {
    setIsSaving(true);
    try {
      const userRef = doc(db, 'users', userId);

      if (!Array.isArray(selectedIncentives)) {
        throw new Error('Selected incentives is not an array');
      }

      await updateDoc(userRef, {
        incentives: selectedIncentives,
      });

      alert('Incentives saved successfully!');
    } catch (error) {
      console.error('Error saving incentives:', error.message);
      alert(`Failed to save incentives: ${error.message}`);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="incentive-selector">

      {/* Selected Incentives */}
      <div className="selected-incentives-container">
  {selectedIncentives.length > 0 ? (
    selectedIncentives.map((id) => {
      const incentive = allIncentives.find((inc) => inc.ID_Incentivo === id);

      // Handle case where incentive is not found
      if (!incentive) {
        return (
          <div key={id} className="service-box selected-incentive">
            <span className="remove-incentive" onClick={() => removeSelectedIncentive(id)}>
              &times;
            </span>
            <span style={{ color: '#2c2c2c', fontWeight: 'bold' }}>
              Incentive Not Found
            </span>
            <div>ID: {id}</div>
          </div>
        );
      }

      return (
        <div key={id} className="service-box selected-incentive">
          <span className="remove-incentive" onClick={() => removeSelectedIncentive(id)}>
            &times;
          </span>
          <span style={{ color: '#2c2c2c', fontWeight: 'bold' }}>
            {incentive.ID_Incentivo}
          </span>
          <div>{incentive?.Titolo || 'Incentive'}</div>
        </div>
      );
    })
  ) : (
    <p className="no-selected">No incentives selected.</p>
  )}
</div>


      {/* Search Box */}
      <input
        type="text"
        placeholder="Troviamo incentivi a selezionare..."
        value={searchKeyword}
        onChange={handleSearchChange}
        className="search-box"
      />

      {/* Suggestions Dropdown */}
      {filteredIncentives.length > 0 && (
        <ul className="suggestions-dropdown">
          {filteredIncentives.map((incentive) => (
            <li
              key={incentive.ID_Incentivo}
              onClick={() => handleIncentiveSelect(incentive)}
              className="suggestion-item"
            >
              {incentive.ID_Incentivo}: {incentive.Titolo}
            </li>
          ))}
        </ul>
      )}

      {/* Save Button */}
      <button onClick={saveSelectedIncentives} disabled={isSaving} className="save-button">
        {isSaving ? 'Saving...' : 'Salva'}
      </button>
    </div>
  );
};

export default IncentiveSelector;

///imports
import React, { useEffect, useState, useRef } from "react";
import { db, auth } from "./config/firebase.js";
import {
  getDocs,
  collection,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
  limit,
} from "firebase/firestore";
import Navbar from "./components/Navbar/index.js";
import Home from "./pages/home.js";
import SignUp from "./pages/signup.js";
import Contact from "./pages/contact.js";
import DisclaimerCampus from "./pages/disclaimer-campus.js";
import PartnerContratto from "./pages/contratto.js";
import LogIn from "./pages/login.js";
//import Campus from "./pages/campus.js";
import Checkout from "./pages/checkout.js";
import Cart from "./pages/cart.js";
//import PaymentSuccess from "./pages/paymentSuccess.js";
import Users from "./pages/users.js";
import Layout from "./Layout.js";
import IncentiveSearch from "./pages/agent2.js";
import IncentiveSearchFree from "./pages/agentFree.js";
import IncentiveSearchCommercialista from "./pages/agent2Commercialista.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from "react-router-dom";
import "./App.css";
import DisclaimerModal from "./DisclaimerModal.js";
import { CartProvider } from "./pages/cartContext.js";
import UserPromptModal from "..//src/components/userPrompt.js";
import ThreadUnic from "./pages/threadUnic.js";
import RNA from "./pages/rnaAnalysis.js";
import RNAmain from "./pages/rnaAnalysisMain.js";
import Preapply from "./pages/preApply.js";
//import IncentiveUnic from "./pages/incentiveUnic.js";
//import Survey from "./pages/survey.js";
import WebBot from "./pages/webBot.js";
import HomeBot from "./pages/homechatbot.js";
//import LandingWebBot from "./pages/landingchatbot.js";
import OldNews from "./pages/oldnews.js";
import ServiceOffers from "./pages/serviceOffers.js";
import ServiceOffersProfs from "./pages/serviceOffersProfs.js";
import AboutUser from "./pages/aboutUser.js";
import IncentiveGuessGame from "./pages/incentiveGuessGame.js";
import AboutUserPro from "./pages/aboutpros.js";
import SuccessNotification from "./pages/successNotification.js";
import OldIncentives from "./pages/oldincentives.js";
import { useParams } from "react-router-dom";
//import landingPic from './landing-pic2.png';
//import landingPic from "../src/noun-pyramid-6037917.png";
import credsPic from "../src/noun-apply-job-6223684.png";
import qualifyPic from "../src/noun-award-6182518.png";
import searchPic from "../src/noun-search-6811990.png";
import applyPic from "../src/noun-apply-1903010.png";
import digithon from "../src/digithon.png";
import confisiLogo from "../src/confisiLogo.png";
import formaLogo from "../src/formacontinuaLogo.png";
import cdtLogo from "../src/cdtLogo.png";
import {
  AuthProvider,
  useAuth,
  PrivateRoute,
  UserRoute,
} from "./components/auth-email.js";
import SidebarLayout from "..//src/components/stages.js";
import UsePageTracking from "..//src/usepageTracking.js";
import PostSignup from "./pages/postSignup.js";
import PostSignupNote from "./pages/postsignupnote.js";
import Goals from "./pages/goalsPage.js";
import UserProfile from "./pages/userProfile.js";
import PartnerPortal from "./pages/partnerportal.js";
import PartnerView from "./pages/partnerview.js";
//import PostSignupComms from "./pages/postSignupComms.js";
import Typewriter from "..//src/components/Typewriter.js";
import InputWithFlashingPlaceholder from "..//src/components/placeholder.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Incentivato from "..//src/landing-pic.png";
import PlaceholderReturn from "./placeholderreturn.js";
import NotFound from "./components/notfound.js";
import DiventaPartner from "../src/pages/partner.js";
import Personalizza from "./components/personalizzaCookies.js";
//import UserProfileWrapper from "./components/UserProfileWrapper.js";
import Storage from "../src/pages/folderstorage.js";
import Sidebar from "../src/pages/sidebar.js";
import ProProfile from "../src/pages/proprofile.js";
import Messages from "../src/pages/messages.js";
import Sportello from "../src/pages/sportello.js";
import ReactPlayer from "react-player";
import TestPlayer from "./components/testplayer.js";
import DemoButton from "./components/demobutton.js";
import ThankYou from "../src/pages/demothanks.js";
import Page3 from "../src/pages/page3.js";
import Scouting from "../src/pages/scouting.js";
import Formazione from "../src/pages/formazione.js";
import FormazioneMP from "../src/pages/formazioneMP.js";
import Marketplace from "../src/pages/marketplace.js";
import RFQ from "../src/pages/rfq.js";
import VendorCV from "./pages/vendorCV.js";

function App() {
  const headerStyles = {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "1px",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    wordSpacing: "6px",
    fontSize: "35px",
    //animation: "flash 3s infinite",
  };

  const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

  useEffect(() => {
    const isDisclaimerAccepted =
      localStorage.getItem("disclaimerAccepted") === "true";
    setDisclaimerAccepted(isDisclaimerAccepted);
  }, []);

  const handleAcceptDisclaimer = () => {
    localStorage.setItem("disclaimerAccepted", "true");
    setDisclaimerAccepted(true);
  };

  /*   const SearchRoute = () => {
    const { userRole } = useAuth();

    return (
      <>
        <StageIndicator currentStage={1} />
        {userRole === "commercialista" ? (
          <IncentiveSearchCommercialista />
        ) : (
          <IncentiveSearch />
        )}
      </>
    );
  }; */

  return (
    <CartProvider>
      <div>
        <Router>
          <AuthProvider>
            <UsePageTracking />
            {/*    {!disclaimerAccepted && (
              <DisclaimerModal onAccept={handleAcceptDisclaimer} />
            )}  */}
            <Navbar />{" "}
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <RouteContentWithImage>
                    <Layout />
                  </RouteContentWithImage>
                }
              />
              <Route path="/home" element={<Home />} />
              <Route path="/personalizza" element={<Personalizza />} />
              <Route path="/create" element={<PrivateRoute></PrivateRoute>} />
              <Route path="/service-offers" element={<ServiceOffers />} />
              <Route
                path="/service-offers-pros"
                element={<ServiceOffersProfs />}
              />
              <Route path="/aboutUser" element={<AboutUser />} />
              <Route path="/aboutUserPro" element={<AboutUserPro />} />
              <Route path="/userprofile" element={<UserProfile />} />
              <Route path="/partnerportal" element={<PartnerPortal />} />
              <Route
                path="/partnerview/:name/:partnerId"
                element={<PartnerView />}
              />
              <Route path="/privacypolicy" element={<Contact />} />
              <Route path="/disclaimer-campus" element={<DisclaimerCampus />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<LogIn />} />
              <Route path="/oldIncentives" element={<OldIncentives />} />
              <Route path="/news" element={<OldNews />} />
              <Route path="/folderstorage" element={<Storage />} />
              {/* <Route path="/sidebar" element={<Sidebar />} /> */}
              <Route path="/proprofile" element={<ProProfile />} />
              <Route path="/messages" element={<Messages />} />
              <Route path="/sportelloConfisi" element={<Sportello />} />
              <Route path="/page3" element={<Page3 />} />
              <Route path="/scouting" element={<Scouting />} />
              <Route path="/avvisi" element={<Formazione />} />
              <Route
                path="/marketplace/formazione"
                element={<FormazioneMP />}
              />
              <Route path="/marketplace/agevolazione" element={<Page3 />} />
              <Route path="/marketplace" element={<Marketplace />} />
              <Route path="/myrfq" element={<RFQ />} />
              <Route path="/vendorcv" element={<VendorCV />} />
              {/* <Route
                path="/search"
                element={
                  <>
                    <StageIndicator currentStage={1} />
                    <IncentiveSearch />
                  </>
                }
              />  */}
              <Route path="/search" element={<IncentiveSearch />} />
              <Route path="/freeSearch" element={<IncentiveSearchFree />} />
              <Route
                path="/searchpros"
                element={<IncentiveSearchCommercialista />}
              />
              <Route path="/diventapartner" element={<DiventaPartner />} />
              <Route path="/threadUnic/:threadId" element={<ThreadUnic />} />
              <Route path="/incentivoAnalysis/" element={<RNA />} />
              <Route path="/incentivoAnalysisMain/" element={<RNAmain />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route
                path="/threadUnic/:assistant/:threadId/:incentiveId"
                element={
                  <SidebarLayout currentStage={2}>
                    <ThreadUnic />
                  </SidebarLayout>
                }
              />
              <Route
                path="/threadUnic/:assistant/:threadId/:incentiveId/Apply"
                element={
                  <SidebarLayout currentStage={3}>
                    <Preapply />
                  </SidebarLayout>
                }
              />

              {/*  <Route
                path="/:assistant/:incentiveId"
                element={
                  <>
                    {console.log("Rendering IncentiveUnic component with route parameters")}
                    <IncentiveUnic />
                  </>
                }
              /> */}
              <Route
                path="/:assistant/:incentiveId"
                element={() => {
                  window.location.href = `https://incentivato.it${window.location.pathname}`;
                  return null;
                }}
              />
              <Route path="*" element={<PlaceholderReturn />} />
              <Route path="/placeholder-test" element={<PlaceholderReturn />} />
              <Route path="*" element={<NotFound />} />
              {/* <Route
                path="/survey/:assistant/:threadId/"
                element={
                  <>
                    <Survey />
                  </>
                }
              /> */}
              <Route
                path="/webBot/:assistant/:threadId/"
                element={
                  <>
                    <WebBot />
                  </>
                }
              />
              <Route path="/webBot/:assistant/" element={<WebBot />} />
              {/* <Route
                path="/landingbot/:assistant/:threadId/"
                element={
                  <>
                    <LandingWebBot />
                  </>
                }
              />
              <Route
                path="/landingbot/:assistant/"
                element={<LandingWebBot />}
              /> */}
              <Route
                path="/strategy/:assistant/:threadId/"
                element={
                  <>
                    <PostSignup />
                  </>
                }
              />
              <Route path="/strategy/:assistant/" element={<PostSignup />} />
              <Route path="/postsignupnote" element={<PostSignupNote />} />
              <Route path="/goals/:assistant/" element={<Goals />} />
              <Route
                path="/goals/:assistant/:threadId/"
                element={
                  <>
                    <Goals />
                  </>
                }
              />
              <Route path="/strategy/:assistant/" element={<PostSignup />} />

              {/* <Route
                path="/campusFeedback"
                element={
                  <UserRoute>
                    {" "}
                    <Initmaps />{" "}
                  </UserRoute>
                }
              /> */}
              <Route path="/cart" element={<Cart />} />
              <Route path="/paymentSuccess" element={<SuccessNotification />} />
              <Route path="/IGG" element={<IncentiveGuessGame />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/userlogin" element={<Users />} />
              {/*  <Route
                path="/paymentSuccess/:docId/:paymentIntentId"
                element={<PaymentSuccess />}
              /> */}
              {/* <Route path="/return" element={<Return />} /> */}
              {/* <Route path="/ordercomplete" element={<Return />} /> */}
            </Routes>
          </AuthProvider>
        </Router>
        {/* <div className="email">Email: info@capital-ai.eu </div> */}
        <div className="social-navigation">
          <ul style={{ display: "flex", listStyle: "none", padding: 0 }}>
            {/* LinkedIn */}
            <li style={{ margin: "0 10px" }}>
              <a
                href="https://www.linkedin.com/company/incentivato/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <i className="fab fa-linkedin" style={{ fontSize: "34px" }}></i>
              </a>
            </li>

            {/* Instagram */}
            <li style={{ margin: "0 10px" }}>
              <a
                href="https://www.instagram.com/incentivato/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <i
                  className="fab fa-instagram"
                  style={{ fontSize: "34px" }}
                ></i>
              </a>
            </li>

            {/* X (Twitter) */}
            <li style={{ margin: "0 10px" }}>
              <a
                href="https://x.com/incentivato"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="X"
              >
                <i className="fab fa-twitter" style={{ fontSize: "34px" }}></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="capdeets">
          © 2025 Incentivato. Prodotto di proprietà di Capital AI Srl.
        </div>
        <div className="capdeets">
          Tutti i diritti riservati. Partita IVA: 13856590966.
        </div>
        <div className="capdeets">Contact: 02 8128 2670</div>
        {/* <div className="sources no-print">
          Sources: OpenData (incentivi.gov.it), RNA (rna.gov.it)
        </div> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "black", 
          }}
        >
          <img src={digithon} alt="A" className="digithon, no-print" />
        </div>
      </div>
    </CartProvider>
  );
}

// Custom component for conditionally rendering content with an image
function RouteContentWithImage() {
  // Get the current route pathname
  const pathname = window.location.pathname;

  // Conditionally render the image
  const renderImage = pathname === "/";

  const navigate = useNavigate();

  const [showDropdown, setShowDropdown] = useState(false);

  ////////////////////////////////////////////////////////////////////////////////////
  //text entry to fb db
  const [newQ1, setNewQ1] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const questionsCollectionRef = collection(db, "questions");
  const threadsCollectionRef = collection(db, "threads");
  const newsCollectionRef = collection(db, "news");
  const [lastVisible, setLastVisible] = useState(null);

  useEffect(() => {
    if (auth.currentUser) {
      getEnrichedThreads();
    } else {
      //console.log("Waiting for user authentication...");
    }
  }, [auth.currentUser]); // Or simply []

  const getEnrichedThreads = async () => {
    try {
      const userId = auth.currentUser ? auth.currentUser.uid : null;
      if (!userId) {
        //console.error("User is not authenticated.");
        return;
      }

      // Fetch all threads and map them by threadId
      const threadTitles = {};
      const threadsSnapshot = await getDocs(threadsCollectionRef);
      threadsSnapshot.forEach((doc) => {
        const threadData = doc.data();
        threadTitles[threadData.threadId] = threadData.title;
      });

      // Fetch questions and enrich them with titles
      const q = query(
        questionsCollectionRef,
        where("userId", "==", userId),
        orderBy("timestamp", "desc")
      );
      const querySnapshot = await getDocs(q);

      let threadsArray = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        title: threadTitles[doc.data().threadId] || "No Title",
      }));

      // Deduplicate threads based on threadId, maintaining the latest entry (assuming latest by timestamp)
      const uniqueThreads = {};
      threadsArray.forEach((thread) => {
        // assuming threadId is always available
        if (
          !uniqueThreads[thread.threadId] ||
          uniqueThreads[thread.threadId].timestamp.seconds <
            thread.timestamp.seconds
        ) {
          uniqueThreads[thread.threadId] = thread;
        }
      });

      // Convert the uniqueThreads object back to an array
      setThreads(Object.values(uniqueThreads));
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    getEnrichedThreads();
  }, []); // Dependency array is empty, so this runs once on component mount

  const [showUserPrompt, setShowUserPrompt] = useState(false);
  useEffect(() => {
    const userHasVisited = localStorage.getItem("userHasVisited");
    if (!userHasVisited) {
      setShowUserPrompt(true);
    }
  }, []);
  const handleClose = () => {
    localStorage.setItem("userHasVisited", "true");
    setShowUserPrompt(false);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////

  const [question, setQuestion] = useState("");
  const [docId, setDocId] = useState("");
  const [threadId, setThreadId] = useState("");
  //const [jsonData, setJsonData] = useState(Opendata); // Ensure Opendata is defined
  const [apiResponse, setApiResponse] = useState("");
  const [messages, setMessages] = useState([
    {
      role: "system",
      content:
        "You are a helpful assistant with good general knowledge for checking facts",
      file_ids: "123",
    },
  ]);

  const [currentThreadId, setCurrentThreadId] = useState(null);

  const filteredQuestions = questionList.filter((question) =>
    currentThreadId ? question.threadId === currentThreadId : true
  );

  const [threads, setThreads] = useState([]);

  // useEffect(() => {
  //   const fetchThreads = async () => {
  //     const threadsSnapshot = await getDocs(threadsCollectionRef);

  //     let threadsArray = threadsSnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       threadId: doc.data().threadId,
  //       title: doc.data().title,
  //       timestamp: doc.data().timestamp,
  //     }));

  //     // Sort threadsArray by timestamp in descending order
  //     threadsArray.sort((a, b) => {
  //       const timestampA = a.timestamp ? a.timestamp.seconds : 0;
  //       const timestampB = b.timestamp ? b.timestamp.seconds : 0;
  //       return timestampB - timestampA;
  //     });

  //     // Filter for unique threadIds
  //     const uniqueThreads = threadsArray.reduce((acc, current) => {
  //       const x = acc.find((item) => item.threadId === current.threadId);
  //       if (!x) {
  //         return acc.concat([current]);
  //       } else {
  //         return acc;
  //       }
  //     }, []);

  //     setThreads(uniqueThreads);
  //   };

  //   fetchThreads();
  // }, []);

  const [title, setTitle] = useState("");
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    console.log("Title updated:", event.target.value);
  };

  const handleThreadUnic = (threadId) => {
    navigate(`/threadUnic/${threadId}`);
  };

  const startNewConversation = async (
    assistant = "asst_EcSBNs1B1atKqgqVaHwvFtAb"
  ) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = `Ciao, mi piace fare questo indaggio`;
      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message: userMessage }),
      });
      //console.log("API Base URL:", API_BASE_URL);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      //console.log("Response data:", data); // Log the entire response data
      localStorage.setItem("currentThreadId", data.thread_id);
      //console.log("Stored thread_id in localStorage:", data.thread_id);

      if (data.thread_id && assistant) {
        //console.log("New thread ID:", data.thread_id, "assistant:", assistant);
        /* navigate(`/survey/${assistant}/${data.thread_id}`, {
          state: { title: title },
        }) */
      } else {
        console.error(
          "Thread ID or Assistant ID was not returned from the server."
        );
      }
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      const newsCollectionRef = collection(db, "news");
      const q = query(
        newsCollectionRef,
        orderBy("timestamp", "desc"),
        limit(3)
      );
      const querySnapshot = await getDocs(q);
      const fetchedNews = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNews(fetchedNews);
    };

    fetchNews();
  }, []);

  const handleLike = async (articleId, currentLikes) => {
    const articleRef = doc(db, "news", articleId);
    const newLikes =
      typeof currentLikes === "number" && !isNaN(currentLikes)
        ? currentLikes + 1
        : 1;
    await updateDoc(articleRef, {
      likes: newLikes,
    });

    // Update the state immediately for UI feedback
    setNews((prevNews) =>
      prevNews.map((article) => {
        if (article.id === articleId) {
          return { ...article, likes: newLikes };
        }
        return article;
      })
    );
  };

  const [targetRef, setTargetRef] = useState(null);

  const NewsSlideshow = ({ news }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      // Ensure there is news data before setting the interval
      if (news && news.length > 0) {
        const intervalId = setInterval(() => {
          setCurrentIndex((current) => (current + 1) % news.length);
        }, 8000); // Rotate news every 5 seconds

        return () => clearInterval(intervalId); // Clean up the interval on unmount
      }
    }, [news]); // Depend on 'news' to restart the effect when news updates

    if (!news || news.length === 0) {
      return <p>Loading news...</p>; // Handle case where news is not yet available
    }

    return (
      <div className="news-slideshow">
        {news.length > 0 ? (
          <div className="news-article">
            <h2 className="article-title">{news[currentIndex].title}</h2>
            <img
              src={news[currentIndex].image_url}
              className="image-article"
              alt={news[currentIndex].title}
            />
            <p className="article-summary">{news[currentIndex].summary}</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <span style={{ paddingLeft: "5px", fontSize: "16px" }}>
                {news[currentIndex].likes}
              </span>
            </div>
          </div>
        ) : (
          <p>Loading news...</p>
        )}
      </div>
    );
  };

  const videoRef = useRef(null); // Create a ref to access the video element
  const [isPlaying, setIsPlaying] = React.useState(false); // State to track if the video is playing

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying); // Toggle play/pause state
    }
  };

  return (
    <div style={{ position: "relative", textAlign: "center", border: "none" }}>
      <header>
          <h1 className="sloganStyles2" style={{paddingLeft: "60px"}}>ACCELERATED FUNDING APPLICATIONS</h1>
        <div className="container-flex">
          <section className="landing-width">
            <div className="newconvo-title">
              <div className="sloganContainer">
                <div className="frontpage-container">
                  <h2 className="sloganStyles" style={{ order: 2 }}>
                    Streamline your Finance
                  </h2>
                  <h2
                    className="sloganStyles"
                    style={{ order: 1, color: "#FF5758" }}
                  >
                    with&nbsp;
                    <span style={{ color: "#FF5758" }}>
                      <Slideshow />
                    </span>
                  </h2>
                  <p className="captionStyles" style={{ order: 4 }}>
                    Incentivato aiuta ad accelerare la ricerca e la richiesta di finanziamenti.
                  </p>
                  <p className="captionStyles" style={{ order: 4 }}>
                    Ci pensiamo noi mentre tu ti concentri sulla tua attività!
                  </p>
                </div>
              </div>
            </div>
          </section>
  
          <aside className="side-contentv">
            <div className="video-container">
              <TestPlayer />
            </div>
          </aside>
        </div>
  
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "20px", fontSize: "25px", fontFamily: "sans-serif" }}>
          <button
            className="indovina-button"
            onClick={() => window.location.href = "/thank-you"}
          >
            Prenota Demo
            <span className="arrow-circle">
              {/* SVG unchanged */}
            </span>
          </button>
        </div>
      </header>
  
      <main>
        <section className="process-wrapper">
          <div className="flex-responsive-container">
            <img src={formaLogo} alt="Forma Logo" className="landing-logos other-logos" />
            <img src={confisiLogo} alt="Confisi Logo" className="landing-logos other-logos" />
            <img src={cdtLogo} alt="Angels Logo" className="landing-logos angels-logo" />
          </div>
  
          <h2 className="process-title">Come aiutiamo?</h2>
         
          <div className="process-container">
            <div className="process-box" style={{ cursor: "pointer" }}>
              <div className="process-number">1</div>
              <p className="process-description-title">
              Importazione Veloce delle Aziende
              </p>
              <p className="process-description">
              Aggiungi facilmente Partite IVA e ottieni subito i dati aziendali ufficiali grazie all'integrazione con le Camere di Commercio.
              </p>
            </div>
            <div className="process-box">
              <div className="process-number">2</div>
              <p className="process-description-title">
              Obiettivi e Matching con l’AI
              </p>
              <p className="process-description">
              Raccogli gli obiettivi aziendali e lascia che la nostra AI analizzi automaticamente i dati per suggerire gli incentivi più adatti.
              </p>
            </div>
            <div className="process-box" style={{ cursor: "pointer" }}>
              <div className="process-number">3</div>
              <p className="process-description-title">
              Messaggi di Scouting & Tracciamento
              </p>
              <p className="process-description">
              Invia messaggi mirati alle aziende per sondare l’interesse e traccia automaticamente le risposte e i progressi.
              </p>
            </div>
            <div className="process-box" style={{ cursor: "pointer" }}>
              <div className="process-number">4</div>
              <p className="process-description-title">Pre-Screening e Verifica Requisiti</p>
              <p className="process-description">
              Valutiamo ogni azienda in base ai criteri dei bandi per pre-qualificare le opportunità e massimizzare il tasso di successo.
              </p>
            </div>
            <div className="process-box" style={{ cursor: "pointer" }}>
              <div className="process-number">5</div>
              <p className="process-description-title">AI Spiegazioni Personalizzate</p>
              <p className="process-description">
              I nostri strumenti AI aiutano a spiegare i bandi in modo semplice e comprensibile, facilitando la decisione da parte delle aziende.{" "}
              </p>
            </div>
            <div className="process-box" style={{ cursor: "pointer" }}>
              <div className="process-number">6</div>
              <p className="process-description-title">Preventivi & Supporto Completo</p>
              <p className="process-description">
              Quando l’azienda decide di procedere, richiediamo preventivi dal nostro network di partner affidabili e seguiamo ogni fase fino alla candidatura finale.{" "}
              </p>
            </div>
          </div>
        
        </section>
  
        <section>
          <h2 className="solutions">INCENTIVI STRUMENTALI</h2>
          <div className="image-fp-container">
            {[searchPic, qualifyPic, credsPic, applyPic].map((img, idx) => (
              <div key={idx} className="image-item" style={{ cursor: "pointer" }}>
                <img src={img} alt="" />
                <p className="type-fonts">{["SEARCH", "QUALIFY", "IDENTIFY", "APPLY"][idx]}</p>
              </div>
            ))}
          </div>
        </section>
  
        <section style={{ background: "black",color: "white", fontFamily: 'sans-serif' }}>
          <div className="newsunic-container">
          <article className="blog-article">
        <header >
        <h1 className="notizia">BLOG</h1>

        {/* ARTICLE 1 */}
        <h2 className="left-border-highlight">
  Perché ottenere un incentivo in Italia è così complicato? Guida pratica alla burocrazia della finanza agevolata
</h2>
          <p className="article-meta" style={{  fontFamily: 'sans-serif', color: "#FF5758" }}>
            Pubblicato da <strong>Incentivato</strong> il 26 marzo 2025
          </p>
        </header>

        <p  className="article-paragraph">
          Ottenere un incentivo pubblico in Italia rappresenta una sfida complicata per molte imprese
          e professionisti. Nonostante le numerose opportunità offerte dalla finanza agevolata,
          spesso l'accesso a queste risorse risulta difficile a causa della complessità burocratica
          e delle procedure amministrative lunghe e articolate.
        </p>

        <p  className="article-paragraph">
          In questo articolo analizzeremo le cause principali di queste difficoltà, offriremo strategie
          pratiche per superarle e metteremo a disposizione risorse concrete per chi desidera affrontare
          con successo il percorso per accedere agli incentivi.
        </p>

        {/* Image */}
        <img className="image-article"
          src="https://storage.googleapis.com/comm-mvp.appspot.com/images/complicato.png"
          alt="Perché ottenere incentivi in Italia è complicato"
        />

        <h3 className="article-heading">Le cause principali della complessità burocratica italiana:</h3>

        <p className="article-paragraph"><strong>1. Stratificazione normativa e regolamentare: </strong>
          In Italia, la normativa relativa alla finanza agevolata è caratterizzata da una sovrapposizione
          di leggi nazionali, regionali ed europee. Questo genera confusione tra gli imprenditori,
          che spesso trovano difficile individuare l'incentivo più adatto e capire chiaramente
          i requisiti necessari per ottenerlo.
        </p>

        <p className="article-paragraph"><strong>2. Procedure amministrative lente e complesse: </strong>
          La documentazione necessaria per richiedere incentivi è spesso voluminosa e richiede
          tempo per essere preparata. A ciò si aggiungono tempi di attesa prolungati da parte
          degli enti incaricati, rallentando significativamente l'accesso ai finanziamenti.
        </p>

        <p className="article-paragraph"><strong>3. Mancanza di trasparenza e chiarezza nelle comunicazioni: </strong>
          Spesso le informazioni fornite da enti e istituzioni pubbliche risultano frammentate,
          non sempre aggiornate e talvolta poco comprensibili, rendendo difficile per le imprese
          prendere decisioni tempestive e corrette.
        </p>

        <h2 className="article-heading">Analisi dei principali ostacoli burocratici</h2>

        <p className="article-paragraph">
          La quantità di documenti necessari per richiedere un incentivo può essere estremamente elevata.
          Alcuni documenti richiesti abitualmente includono:
        </p>
        <ul className="article-list">
          <li >Business plan dettagliato</li>
          <li >Preventivi e fatture proforma</li>
          <li >Dichiarazioni fiscali e previdenziali aggiornate</li>
          <li >Certificati di regolarità contributiva (DURC)</li>
          <li >Dichiarazioni di conformità alle norme europee (ad esempio, regime “de minimis”)</li>
        </ul>

        <h3 className="article-heading">La gestione della rendicontazione finanziaria</h3>
        <p className="article-paragraph">
          Una volta ottenuto l’incentivo, inizia una fase complessa di rendicontazione delle spese sostenute.
          Molte aziende incontrano difficoltà nel produrre documenti adeguati per dimostrare
          l’effettivo utilizzo dei fondi ricevuti.
        </p>

        <h3 className="article-heading">Strategie pratiche per affrontare la burocrazia degli incentivi</h3>

        <p className="article-paragraph"><strong>1. Informarsi attraverso risorse affidabili: </strong>
          Per orientarsi meglio nella normativa vigente e negli incentivi disponibili, è fondamentale
          utilizzare risorse affidabili, come <strong>incentivato</strong>, che offre informazioni
          chiare e aggiornate sui bandi e le misure di sostegno.
        </p>

        <p className="article-paragraph"><strong>2. Affidarsi a consulenti specializzati: </strong>
          La consulenza di esperti in finanza agevolata può essere determinante per evitare errori,
          gestire correttamente la documentazione e risparmiare tempo. È importante selezionare
          professionisti con referenze affidabili e una comprovata esperienza.
        </p>

        <p className="article-paragraph"><strong>3. Digitalizzare e automatizzare la documentazione: </strong>
          Utilizzare software e piattaforme digitali dedicate può ridurre sensibilmente gli errori
          e velocizzare i tempi di preparazione della documentazione. Soluzioni come piattaforme
          di gestione documentale o strumenti digitali specializzati in finanza agevolata
          rappresentano un grande vantaggio.
        </p>

        <h2 className="article-heading">Esempi pratici di incentivi: come gestire la procedura</h2>
        <p className="article-paragraph">
          Ecco alcuni incentivi attualmente attivi che presentano procedure amministrative chiare,
          se affrontate con la giusta strategia:
        </p>
        <ul className="article-list">
          <li>
            <a
              href="https://incentiveunic.incentivato.it/asst_ycYM1GKfORqZtHSOtRWUs3rB/smart-start-italia-sostegno-alle-startup-innovative"
              target="_blank"
              rel="noopener noreferrer"
              style={{textAlign: "left"}}
            >
              Smart&Start Italia
            </a>{" "}
            – sostiene le startup innovative con contributi e finanziamenti agevolati, a patto di
            presentare un progetto ben strutturato e di alto contenuto tecnologico.
          </li>
          <li>
            <a
              href="https://incentiveunic.incentivato.it/asst_XDTZdLdDMrvR2o6iRs7jovVr/on-oltre-nuove-imprese-a-tasso-zero"
              target="_blank"
              rel="noopener noreferrer"
            >
              Nuove Imprese a Tasso Zero
            </a>{" "}
            – favorisce l’avvio di micro e piccole imprese con finanziamenti a tasso agevolato
            e contributi a fondo perduto, promuovendo imprenditorialità e crescita.
          </li>
          <li>
            <a
              href="https://incentiveunic.incentivato.it/asst_0nay8P2ZJKW2aeS53fQKsReN/beni-strumentali-nuova-sabatini"
              target="_blank"
              rel="noopener noreferrer"
            >
              Beni Strumentali – Nuova Sabatini
            </a>{" "}
            – offre finanziamenti agevolati per l’acquisto di beni materiali e immateriali,
            a supporto degli investimenti in tecnologia e innovazione.
          </li>
        </ul>

        <h2 className="article-heading">Benchmark europeo: cosa fa meglio l’Europa rispetto all’Italia?</h2>
        <p className="article-paragraph">
          In altri paesi europei, come Francia e Germania, le procedure burocratiche risultano più snelle
          e trasparenti:
        </p>
        <ul className="article-list">
          <li>
            <strong>Francia</strong> – il sistema di incentivi gestito da Bpifrance offre procedure
            digitalizzate e semplici da utilizzare.
          </li>
          <li>
            <strong>Germania</strong> – il sistema tramite KfW prevede iter burocratici molto più rapidi
            rispetto a quelli italiani, con maggiore chiarezza su documentazione e tempi.
          </li>
        </ul>

        <p className="article-paragraph">
          L’Italia potrebbe prendere ispirazione da questi esempi europei per migliorare e semplificare
          le proprie procedure burocratiche.
        </p>

        <h2 className="article-heading">Conclusione: come affrontare con successo la burocrazia italiana</h2>
        <p className="article-paragraph">
          Sebbene ottenere un incentivo in Italia sia complicato, le opportunità di finanza agevolata
          rimangono estremamente interessanti e vantaggiose. Una pianificazione accurata, il ricorso
          a consulenze esperte e la digitalizzazione dei processi interni possono trasformare
          un percorso complesso in un’opportunità concreta di crescita.
        </p>

        <p className="article-paragraph">
          Superare le difficoltà burocratiche significa non solo avere pazienza, ma soprattutto
          acquisire strumenti e competenze specifiche che renderanno la tua impresa più competitiva
          e pronta ad affrontare le sfide future.
        </p>
      </article>

{/* ARTICLE 2 */}

      <article className="blog-article" style={{marginTop: "150px"}}>
        <header>
          <h2 className="left-border-highlight" >Breve storia della finanza agevolata in Italia: dalle origini ai giorni nostri</h2>
          <p className="article-meta" style={{  fontFamily: 'sans-serif', color: "#FF5758" }}>
            Pubblicato da <strong>Incentivato</strong> il 26 marzo 2025
          </p>
        </header>

        <p className="article-paragraph">
          La finanza agevolata rappresenta uno strumento indispensabile per promuovere lo sviluppo
          economico, sociale e industriale delle imprese italiane. Consiste in finanziamenti pubblici
          e agevolazioni fiscali concessi con condizioni particolarmente vantaggiose, al fine di
          incentivare investimenti strategici per l’economia nazionale.
        </p>

        <p className="article-paragraph">
          Ma quando nasce realmente questo concetto in Italia, e come si è evoluto nel corso del tempo
          fino a diventare centrale nelle strategie economiche moderne?
        </p>

        <img className="image-article"
          src="https://storage.googleapis.com/comm-mvp.appspot.com/images/storia.png"
          alt="Immagine sulla storia della finanza agevolata"
        />

        <p className="article-paragraph">
          In questo articolo ripercorreremo le tappe principali della storia della finanza agevolata,
          approfondiremo gli strumenti più utilizzati oggi, fornendo inoltre risorse concrete per
          chi vuole approfondire o accedere agli incentivi attualmente disponibili.
        </p>

        <h2 className="article-heading">Le origini storiche: il Piano Marshall e la ricostruzione post-bellica</h2>
        <p className="article-paragraph">
          La prima grande manifestazione di finanza agevolata in Italia risale alla fine della Seconda Guerra
          Mondiale, nel 1948, con l’introduzione del <strong>Piano Marshall (European Recovery Program)</strong>.
          Con questo piano, gli Stati Uniti fornirono ingenti risorse economiche per ricostruire infrastrutture
          e industrie devastate dal conflitto bellico. L’Italia ricevette in totale circa 1,5 miliardi di dollari,
          che permisero il rilancio di molti settori chiave.
        </p>

        <h2 className="article-heading">Gli anni '50 e '60: dalla Cassa per il Mezzogiorno all’IRI</h2>
        <p className="article-paragraph">
          Negli anni '50, l’Italia consolidò la propria strategia di finanza agevolata creando strumenti ad hoc
          come la <strong>Cassa per il Mezzogiorno</strong>, fondata nel 1950 con l’obiettivo di colmare il divario
          economico tra il Sud e il Nord del Paese.
        </p>
        <p className="article-paragraph">
          Nello stesso periodo, l’<strong>IRI (Istituto per la Ricostruzione Industriale)</strong>, nato nel 1933,
          ampliò il suo raggio di azione, fornendo finanziamenti agevolati e partecipazioni statali per sostenere
          settori industriali strategici.
        </p>

        <h2 className="article-heading">Gli anni '70 e '80: tra crisi e modernizzazione</h2>
        <p className="article-paragraph">
          Durante gli anni '70, caratterizzati da crisi petrolifere e turbolenze economiche internazionali,
          l’Italia rafforzò il sistema degli incentivi pubblici, aumentando gli interventi diretti a favore
          delle imprese tramite contributi a fondo perduto e finanziamenti a tasso agevolato.
        </p>
        <p className="article-paragraph">
          Negli anni '80, il focus della finanza agevolata si spostò verso l’innovazione tecnologica
          e l’internazionalizzazione, con numerosi incentivi volti ad aiutare le PMI a competere sui mercati globali.
        </p>

        <h2 className="article-heading">Anni '90: la svolta della Legge 488/92</h2>
        <p className="article-paragraph">
          Un punto di svolta cruciale nella storia della finanza agevolata è rappresentato dalla
          <strong>Legge 488 del 1992</strong>, che introdusse un sistema più trasparente e semplificato
          per l'erogazione degli incentivi. Questa legge permetteva alle imprese di accedere a contributi
          a fondo perduto per investimenti produttivi in regioni svantaggiate, rappresentando un importante
          volano per lo sviluppo economico locale.
        </p>
        <p className="article-paragraph">
          La legge 488 diventò in breve tempo uno dei principali strumenti di politica industriale,
          distribuendo risorse in maniera equa e meritocratica secondo criteri come sostenibilità finanziaria
          e creazione di posti di lavoro.
        </p>

        <h2 className="article-heading">L’ingresso nell’Unione Europea e i Fondi Strutturali</h2>
        <p className="article-paragraph">
          Con l’adesione all’Unione Europea, l’Italia ha iniziato a beneficiare dei
          <strong>Fondi Strutturali Europei</strong>, mirati a favorire la coesione economica e sociale
          tra i Paesi membri. Nel ciclo 2014-2020, l’Italia ha avuto a disposizione circa 105 miliardi di euro
          di fondi strutturali europei.
        </p>
        <p className="article-paragraph">
          Per il periodo 2021-2027, questi fondi sono ulteriormente aumentati fino a 74 miliardi di euro,
          focalizzandosi su innovazione, transizione verde, digitalizzazione e occupazione
          (<em>Fonte: economyup.it</em>).
        </p>

        <h2 className="article-heading">La decentralizzazione regionale: vantaggi e criticità</h2>
        <p className="article-paragraph">
          Un altro sviluppo significativo degli ultimi decenni è stata la crescente responsabilizzazione delle
          Regioni nella gestione diretta degli incentivi locali. Questo ha permesso di adattare le misure
          alle specifiche necessità territoriali, garantendo maggiore flessibilità.
        </p>
        <p className="article-paragraph">
          Tuttavia, la decentralizzazione ha reso necessario un coordinamento più stretto fra Regioni
          e Stato centrale per evitare sovrapposizioni e garantire una reale efficacia delle misure
          di sostegno economico.
        </p>

        <h2 className="article-heading">Esempi di incentivi attualmente disponibili in Italia</h2>
        <ul className="article-list">
          <li>
            <strong>
              <a
                href="https://incentiveunic.incentivato.it/asst_0nay8P2ZJKW2aeS53fQKsReN/beni-strumentali-nuova-sabatini"
                target="_blank"
                rel="noopener noreferrer"
              >
                Nuova Sabatini 2025
              </a>
            </strong>
            : favorisce l’acquisto di beni strumentali per le PMI.
          </li>
        </ul>

        <h2 className="article-heading">Conclusione: quale futuro per la finanza agevolata italiana?</h2>
        <p className="article-paragraph">
          La finanza agevolata in Italia ha attraversato fasi molto diverse, adattandosi continuamente
          alle esigenze economiche del Paese e alle dinamiche europee e globali. L’attuale panorama,
          caratterizzato da un mix di strumenti regionali, nazionali ed europei, offre opportunità importanti
          per la crescita delle imprese, soprattutto se accompagnate da una maggiore semplificazione burocratica.
        </p>
        <p className="article-paragraph">
          Il futuro della finanza agevolata passa necessariamente da una maggiore digitalizzazione e trasparenza
          delle procedure, permettendo una più ampia e rapida diffusione delle risorse disponibili.
        </p>
        <p className="article-paragraph">
          La sfida principale rimane quella di informare correttamente imprenditori e professionisti
          sugli strumenti disponibili, incentivandone un utilizzo efficace e virtuoso,
          a beneficio dell’intera economia nazionale.
        </p>
      </article>
      
            <h2 className="notizia">NOTIZIA</h2>
            <div className="news-container">
              {news.length ? news.map((article, index) => (
                <article key={index} className="news-article">
                  <h3 className="article-title">{article.title}</h3>
                  <a href={`https://incentiveunic.incentivato.it/${article.assistant}/${article.slug}`} target="_blank" rel="noopener noreferrer">
                    <img src={article.image_url} className="image-article" alt="News" />
                  </a>
                  <p className="article-paragraph">{article.summary}</p>
                  <button onClick={() => handleLike(article.id, article.likes)} style={{ cursor: "pointer", fontSize: "20px", background: "none", border: "none" }}>
                    {/* SVG unchanged */}
                  </button>
                  <span style={{ fontSize: "18px", color: "white" }}>{article.likes}</span>
                </article>
              )) : <p>Loading news...</p>}
            </div>
            <div className="margin-landing">
              <div
                style={{
                  background: "#092f7a",
                  display: "flex",
                  justifyContent: "space-evenly",
                  background: "white",
                  border: "none",
                }}
              >
                <button
                  type="button"
                  onClick={() => navigate("/login")}
                  className="footer-button"
                >
                  User Portal{" "}
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/signup")}
                  className="footer-button"
                >
                  SignUp{" "}
                </button>
                <button
                  type="button"
                  onClick={() => navigate("/privacypolicy")}
                  className="footer-button"
                >
                  Privacy Policy{" "}
                </button>
              </div>
            </div>
          
          </div>
        </section>
      </main>
    </div>
  );
}

const Slideshow = () => {
  const texts = [
    "AI Matching",
    "1Click Applications",
    "Bespoke Alerts",
    "Expert Support",
    "Flexible Pricing",
  ];
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the current text index and wrap around if necessary
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 1500); // 10000 milliseconds (10 seconds)

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div>
      {/* Display the current text */}
      <div className="text-loop">{texts[currentTextIndex]}</div>
    </div>
  );
};

export default App;

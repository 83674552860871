import React, { useState } from 'react';
import "./placeholder.css";
import avatarFemale1 from "../components/avatars/avatarFemale1.png";
import avatarFemale2 from "../components/avatars/avatarFemale2.png";
import avatarMale1 from "../components/avatars/avatarMale1.png";
import avatarMale2 from "../components/avatars/avatarMale2.png";

const AvatarSelector = ({ onAvatarSelect }) => {
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const avatars = [
    { id: 1, src: avatarFemale1, alt: 'Avatar Femmina 1' },
    { id: 2, src: avatarFemale2, alt: 'Avatar Femmina 2' },
    { id: 3, src: avatarMale1, alt: 'Avatar Maschio 1' },
    { id: 4, src: avatarMale2, alt: 'Avatar Maschio 2' },
  ];

  const handleAvatarClick = (avatar) => {
    setSelectedAvatar(avatar);
    setShowPopup(true); // Show confirmation popup
  };

  const handleAccept = () => {
    onAvatarSelect(selectedAvatar.src); // Pass the selected avatar to parent
    setShowPopup(false);
  };

  const handleCancel = () => {
    setSelectedAvatar(null);
    setShowPopup(false);
  };

  return (
    <div className="avatar-selector">
      <h3>Scegli il tuo Avatar:</h3>
      <div className="avatar-grid">
        {avatars.map((avatar) => (
          <div key={avatar.id} className="avatar-wrapper">
            <img
              src={avatar.src}
              alt={avatar.alt}
              className={`avatar ${selectedAvatar?.id === avatar.id ? 'selected' : ''}`}
              onClick={() => handleAvatarClick(avatar)}
            />

            {/* Confirmation Popup */}
            {showPopup && selectedAvatar?.id === avatar.id && (
              <div className="confirmation-popup">
                <p>Scegli questo avatar?</p>
                <button className="confirm-btn" onClick={handleAccept}>Accetta</button>
                <button className="cancel-btn" onClick={handleCancel}>Annulla</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvatarSelector;


import React, { useState, useEffect, useRef } from "react";
import { ref, getDownloadURL, deleteObject, uploadBytes } from "firebase/storage";
import { db, auth, storage } from "../config/firebase.js";
import {
  collection,
  doc as firestoreDoc,
  getDoc,
  addDoc,
  serverTimestamp,
  where,
  query,
  onSnapshot,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import "./folderstorage.css";
import {
  FaUser,
  FaBuilding,
  FaDollarSign,
  FaFileAlt,
  FaGavel,
  FaEllipsisH,
} from "react-icons/fa";
import UserPromptModal from "..//..//src/components/userPrompt.js";

const Storage = () => {
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [currentUser, setCurrentUser] = useState(null);
  const [requiredDocuments, setRequiredDocuments] = useState({});
  const [error, setError] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const authInstance = auth;
  const fileInputRef = useRef(null);
  const requiredDocumentsId = "2843";
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Monitor Authentication
   useEffect(() => {
       const unsubscribe = auth.onAuthStateChanged((user) => {
         setIsAuthenticated(!!user);
       });
   
       return () => unsubscribe();
     }, []);

      const fetchUserData = async (email) => {
            if (email) {
              try {
                const usersCollectionRef = collection(db, "users");
                const q = query(usersCollectionRef, where("email", "==", email));
                const querySnapshot = await getDocs(q);
        
                if (!querySnapshot.empty) {
                  const userData = querySnapshot.docs[0].data();
                  //console.log("User data found:", userData);
                } else {
                  console.log("No user data found for email:", email);
                }
              } catch (error) {
                console.error("Error fetching user data:", error);
              }
            }
          };

  

  // Fetch Uploaded Documents
  useEffect(() => {
    const fetchUploadedDocuments = () => {
      const user = authInstance.currentUser;
      if (!user) {
        console.error("User not authenticated.");
        return;
      }

      const q = query(
        collection(db, "users_documents"),
        where("userId", "==", user.uid)
      );

      const unsubscribe = onSnapshot(
        q,
        (querySnapshot) => {
          const docs = [];
          querySnapshot.forEach((doc) => {
            docs.push({ id: doc.id, ...doc.data() });
          });
          setUploadedDocuments(docs);
        },
        (error) => {
          console.error("Error fetching uploaded documents:", error);
          setError("Errore durante il caricamento dei documenti caricati.");
        }
      );

      return () => unsubscribe();
    };

    fetchUploadedDocuments();
  }, [authInstance.currentUser]);

  // Handle File Selection and Upload
  const handleFileSelect = async (event) => {
    const user = authInstance.currentUser;
    const userId = user ? user.uid : null;

    if (!userId) {
      alert("User non autenticato");
      return;
    }

    const files = event.target.files;
    if (!files || files.length === 0) {
      alert("Per favore seleziona almeno un file da caricare.");
      return;
    }

    try {
      for (const file of files) {
        const storagePath = `${userId}/${file.name}`;
        const storageRef = ref(storage, storagePath);
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        await addDoc(collection(db, "users_documents"), {
          userId: userId,
          fileName: file.name,
          storagePath: storagePath,
          fileUrl: downloadURL,
          uploadedAt: serverTimestamp(),
        });
      }
      alert("File caricati con successo.");
      event.target.value = null;
    } catch (error) {
      alert(`Errore durante il caricamento dei file: ${error.message}`);
    }
  };

  // Handle Document Deletion
  const handleDelete = async (document) => {
    const confirmDelete = window.confirm(
      `Sei sicuro di voler eliminare "${document.fileName}"?`
    );
    if (!confirmDelete) return;

    try {
      const storageReference = ref(storage, document.storagePath);
      await deleteObject(storageReference);

      const docRef = firestoreDoc(db, "users_documents", document.id);
      await deleteDoc(docRef);

      alert(`"${document.fileName}" è stato eliminato con successo.`);
    } catch (error) {
      alert(`Errore durante l'eliminazione del documento: ${error.message}`);
    }
  };

  // Get Category Icons
  const getCategoryIcon = (category) => {
    switch (category.toLowerCase()) {
      case "dati personali":
        return <FaUser className="block-icon" />;
      case "dati azienda":
        return <FaBuilding className="block-icon" />;
      case "costi":
        return <FaDollarSign className="block-icon" />;
      case "formulari":
        return <FaFileAlt className="block-icon" />;
      case "legale":
        return <FaGavel className="block-icon" />;
      case "altri":
        return <FaEllipsisH className="block-icon" />; // Icon for "Altri" category
      default:
        return <FaEllipsisH className="block-icon" />;
    }
  };

  return (
    <div className="precompile-container">
      <h1 className="precompile-title">Storage Folder</h1>
      {!isAuthenticated && <UserPromptModal />}
      {error && <p className="precompile-error">{error}</p>}

      <div className="documents-section">

        {/* Uploaded Documents */}
        <div className="documents-card uploaded-documents-card">
          <h3 className="documents-title">Documenti Caricati</h3>
          {uploadedDocuments.length > 0 ? (
            <ul className="uploaded-documents-list">
              {uploadedDocuments.map((document) => (
                <li key={document.id} className="uploaded-document-item">
                  <div className="document-info">
                    <a
                      href={document.fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="uploaded-document-link"
                    >
                      {document.fileName}
                    </a>
                  </div>
                  <button
                    onClick={() => handleDelete(document)}
                    className="delete-icon-button"
                    aria-label={`Elimina ${document.fileName}`}
                  >
                    🗑️
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <p className="uploaded-documents-empty">
              Non hai ancora caricato alcun documento.
            </p>
          )}
        </div>
      </div>

      {/* Compilazione Button */}
      <div className="next-button-container">
      <div className="next-button-container">
        <input
            type="file"
            multiple
            ref={fileInputRef}
            onChange={handleFileSelect}
            style={{ display: "none" }}
        />
        <button
            className="upload-button"
            onClick={() => fileInputRef.current.click()}
        >
            Caricare Documenti
        </button>
        </div>

      </div>
    </div>
  );
};

export default Storage;

import React, { useState, useEffect } from "react";
import { auth, googleProvider, db, storage } from "../config/firebase.js";
import {
  query,
  where,
  collection,
  doc as firestoreDoc,
  getDocs,
  doc,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import "../../src/pages/allCSS.css";

const Scouting = () => {
  const [userId, setUserId] = useState(auth.currentUser?.uid || null);
  const [messages, setMessages] = useState([]);
  const [leads, setLeads] = useState([]);
  const [requests, setRequests] = useState([]);
  const [companyMap, setCompanyMap] = useState({});
  const [pivas, setPivas] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [role, setRole] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyDeminimis, setCompanyDeminimis] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedPivaId, setSelectedPivaId] = useState(null);
  const [selectedPivaData, setSelectedPivaData] = useState(null);
  const [authInitialized, setAuthInitialized] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      //console.log("Auth state changed:", user);
      setUserId(user ? user.uid : null);
      setAuthInitialized(true);
    });
    return () => unsubscribe();
  }, []);

  // Fetch emails sent from users_messages
  useEffect(() => {
    if (!userId) return;

    //console.log("Fetching users_messages for userId:", userId);
    const messagesRef = collection(db, "users_messages");
    const q = query(messagesRef, where("userId", "==", userId));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      //console.log("Fetched messages:", fetchedMessages);
      setMessages(fetchedMessages);
    });

    return () => unsubscribe();
  }, [userId]);

  // Fetch sportello requests from users_messages
  useEffect(() => {
    if (!userId) return;

    const requestsRef = collection(db, "users_messages");
    const q = query(requestsRef, where("user", "==", userId));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedRequests = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setRequests(fetchedRequests);
    });

    return () => unsubscribe();
  }, [userId]);

  // Fetch lead clicks from leadClicks
  useEffect(() => {
    if (!userId) return;

    //console.log("Fetching leadClicks for userId:", userId);
    const leadsRef = collection(db, "LeadClicks");
    const q = query(leadsRef, where("userId", "==", userId));

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      if (snapshot.empty) {
        console.log("No leadClicks found for userId:", userId);
      }

      const fetchedLeads = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      //console.log("Fetched leadClicks:", fetchedLeads);

      // Extract PIVA values and fetch corresponding company names
      const pivaList = [...new Set(fetchedLeads.map((lead) => lead.piva))];
      //console.log("Extracted PIVA values:", pivaList);

      if (pivaList.length > 0) {
        const commercialistaRef = collection(db, "commercialista");
        const companyQuery = query(
          commercialistaRef,
          where("piva", "in", pivaList)
        );
        const companySnapshot = await getDocs(companyQuery);

        const companyData = {};
        companySnapshot.forEach((doc) => {
          companyData[doc.data().piva] = doc.data().companyName;
        });

        //console.log("Fetched company data:", companyData);
        setCompanyMap(companyData);
      }

      setLeads(fetchedLeads);
    });

    return () => unsubscribe();
  }, [userId]);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "N/A";
    if (timestamp.toDate) {
      return timestamp.toDate().toLocaleString(); // Convert Firestore Timestamp to JS Date
    }
    return new Date(timestamp).toLocaleString(); // Fallback for already converted timestamps
  };

  ///////////////// Table
  // Fetch PIVA data
  useEffect(() => {
    if (!authInitialized) {
      //console.log("Auth not initialized yet. Skipping Firebase query.");
      return;
    }
  
    if (!userId) {
      console.warn("userId is missing after auth initialized. Exiting.");
      return;
    }
  
    const commercialistaCollection = collection(db, "commercialista");
    const q = query(commercialistaCollection, where("commercialista", "==", userId));
  
    const unsubscribe = onSnapshot(
      q,
      async (snapshot) => {
        //console.log(`Received snapshot with ${snapshot.docs.length} documents.`);
  
        const pivaList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        // Process each PIVA to get metrics
        const updatedPivaList = await Promise.all(
          pivaList.map(async (piva) => {
            // Step 1: Count hyperFilter 'Y' in FilteredData
            const filteredDocRef = doc(db, "filteredData", piva.id);
            let hyperYCount = 0;
  
            try {
              const filteredDocSnap = await getDoc(filteredDocRef);
              if (filteredDocSnap.exists()) {
                const incentivesArray = filteredDocSnap.data().incentives || [];
                hyperYCount = incentivesArray.filter((inv) => inv.hyperFilter === 'Y').length;
                //console.log(`✅ PIVA: ${piva.id}, hyperFilter 'Y' count: ${hyperYCount}`);
              } else {
                console.warn(`⚠️ No FilteredData found for PIVA: ${piva.id}`);
              }
            } catch (error) {
              console.error(`❌ Error accessing filteredData for PIVA: ${piva.id}`, error);
            }
  
            // Step 2: Count Clicks in LeadClicks collection
            let clickCount = 0;
            try {
              const leadClicksCollection = collection(db, "LeadClicks");
              const clickQuery = query(leadClicksCollection, where("piva", "==", piva.piva));
              const clickSnapshot = await getDocs(clickQuery);
              clickCount = clickSnapshot.size;
              //console.log(`🔍 PIVA: ${piva.id}, Clicks found: ${clickCount}`);
            } catch (error) {
              console.error(`❌ Error fetching LeadClicks for PIVA: ${piva.piva}`, error);
            }
  
            // Step 3: Count Emails in users_messages collection
            let emailCount = 0;
            try {
              const messagesCollection = collection(db, "users_messages");
              const messageQuery = query(messagesCollection, where("piva", "==", piva.piva));
              const messageSnapshot = await getDocs(messageQuery);
              emailCount = messageSnapshot.size;
              //console.log(`📧 PIVA: ${piva.id}, Emails found: ${emailCount}`);
            } catch (error) {
              console.error(`❌ Error fetching users_messages for PIVA: ${piva.piva}`, error);
            }
  
            // Step 4: Classify Deminimis
            let deminimisDisplay = "";
            const deminimisValue = parseFloat(piva.deminimis) || 0;
            if (deminimisValue < 200000) {
              deminimisDisplay = "OK";
            }
  
            // Combine all results
            return { ...piva, hyperYCount, clickCount, emailCount, deminimisDisplay };
          })
        );
  
        setPivas(updatedPivaList);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching documents from 'commercialista':", error);
        setLoading(false);
      }
    );
  
    return () => unsubscribe();
  }, [userId, db, authInitialized]);
  

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  /////// START OF JSX

  return (
    <div style={{background: "black"}}>
    <div className="container-scout">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "45px",
          fontWeight: "bold",
          color: "white",
          textAlign: "center",
          backgroundColor: "black",
          marginTop: "10px",
        }}
      >
        Message Scouting
      </div>
      {/* Emails Sent Section */}
      <div className="kpi-container">
        <div className="kpi-box">
          <h2 className="kpi-number">{requests.length}</h2>
          <p className="kpi-label" >Sportello Requests</p>
        </div>
        <div className="kpi-box">
          <h2 className="kpi-number">{messages.length}</h2>
          <p className="kpi-label">Emails Sent</p>
        </div>
        <div className="kpi-box">
          <h2 className="kpi-number">{leads.length}</h2>
          <p className="kpi-label">Links Clicked</p>
        </div>
      </div>
      <div style={{marginTop: "20px"}}>
      {loading ? (
    <p>Loading PIVAs...</p>
  ) : pivas.length > 0 ? (
    <table className="table-piva" style={{ width: "100%", borderCollapse: "collapse", border: "1px solid white" }}>
      <thead className="thead-piva">
        <tr>
          {["PIVA", "Nome", "Deminimis", "LeadsHF", "Clicks", "Emails"].map((header) => (
            <th key={header} style={{ color: "white", border: "1px solid white", padding: "8px", textAlign: "center" }}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="tbody-piva">
        {pivas.map((piva) => (
          <tr key={piva.id}>
            <td style={{ color: "white", border: "1px solid white", padding: "8px" }}>{piva.piva}</td>
            <td style={{ color: "white", border: "1px solid white", padding: "8px" }}>{piva.companyName}</td>
            <td style={{ color: "white", border: "1px solid white", padding: "8px" }}>
              {piva.deminimisDisplay}
            </td>
            <td style={{ color: "white", border: "1px solid white", padding: "8px" }}>{piva.hyperYCount}</td>
            <td style={{ color: "white", border: "1px solid white", padding: "8px" }}>{piva.clickCount}</td>
            <td style={{ color: "white", border: "1px solid white", padding: "8px" }}>{piva.emailCount}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div>Per iniziare inserisci una PIVA</div>
  )}
      </div>

      <table className="styled-table">
        <thead>
          <tr>
            <th>Recipient Email</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {messages.map((msg) => (
            <tr key={msg.id}>
              <td>{msg.recipientEmail}</td>
              <td>{formatTimestamp(msg.timestamp)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Links Clicked Section */}
      <table className="styled-table">
        <thead>
          <tr>
            <th>Clicked Link</th>
            <th>Timestamp</th>
            <th>Company Name</th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => (
            <tr key={lead.id}>
              <td>
                <a href={lead.link} target="_blank" rel="noopener noreferrer">
                  {lead.link}
                </a>
              </td>
              <td>{formatTimestamp(lead.timestamp)}</td>
              <td>{companyMap[lead.piva] || "Unknown"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

export default Scouting;

import React, { useEffect, useState } from "react";
import { db } from "../config/firebase.js";
import { collection, onSnapshot, query } from "firebase/firestore";
import "./formazione.css";

const Formazione = () => {
  const [avvisi, setAvvisi] = useState([]);
  const [fondi, setFondi] = useState([]);
  const [selectedSoggetto, setSelectedSoggetto] = useState("");

  // Fetch Avvisi
  useEffect(() => {
    const qAvvisi = query(collection(db, "AvvisiMonitoringAgent_DB_ready"));
    const unsubscribeAvvisi = onSnapshot(qAvvisi, (snapshot) => {
      const avvisiList = [];
      snapshot.forEach((doc) => {
        avvisiList.push({ id: doc.id, ...doc.data() });
      });
      setAvvisi(avvisiList);
    });
    return () => unsubscribeAvvisi();
  }, []);

  // Derive Fondi from avvisi
  useEffect(() => {
    const soggettiArray = avvisi
      .map((item) => item.Soggetto_Concedente)
      .filter(Boolean);
    setFondi([...new Set(soggettiArray)]);
  }, [avvisi]);

  // Filter avvisi by selected soggetto
  const filteredAvvisi = avvisi.filter((item) =>
    selectedSoggetto ? item.Soggetto_Concedente === selectedSoggetto : true
  );

  // Check if date is in the past
  const isClosed = (date) => {
    const now = new Date();
    return date && new Date(date.seconds * 1000) < now;
  };

  return (
    <div className="avissi-container">
      {/* New Blue Header with Count */}
      <div className="blue-header">
        <span className="avissi-count">{avvisi.length}</span>
        <span className="avissi-title-text">
          Avvisi Formazione Fondo Interprofessionale
        </span>
        <input
          type="text"
          placeholder="🔍 Ricerca per titolo"
          className="avissi-search"
        />
      </div>

      <div className="avissi-content">
        {/* Sidebar */}
        <aside className="avissi-sidebar">
          <h2 className="sidebar-title">Fondi</h2>
          <ul className="sidebar-list">
            <li
              className={!selectedSoggetto ? "active" : ""}
              onClick={() => setSelectedSoggetto("")}
            >
              Tutti
            </li>
            {fondi.map((fondo) => (
              <li
                key={fondo}
                className={selectedSoggetto === fondo ? "active" : ""}
                onClick={() => setSelectedSoggetto(fondo)}
              >
                {fondo}
              </li>
            ))}
          </ul>
        </aside>

        {/* Main Content */}
        <div className="avissi-main-content">
          {filteredAvvisi.length > 0 ? (
            <div className="avissi-grid">
              {filteredAvvisi.map((item) => {
                const criteriaKeys = Object.keys(item)
                  .filter((key) => key.startsWith("Criteria_"))
                  .filter((key) => item[key]?.trim() !== "");

                // Calculate closure status
                const closed = isClosed(item.data_chiusura);

                return (
                  <div key={item.id} className={`avissi-card ${closed ? "closed" : ""}`}>
                    {/* Closed Tag */}
                    {closed && <div className="closed-tag">Closed</div>}

                    <h2 className="avissi-card-title">
                      {item.Titolo || "Titolo non disponibile"}
                    </h2>

                    <hr className="avissi-separator" />

                    {/* Image with increased size */}
                    {item.urlImage && (
                      <div className="avissi-image">
                        <img
                          src={item.urlImage}
                          alt={item.Titolo || "Immagine Avviso"}
                          className="avissi-image-fixed"
                        />
                      </div>
                    )}

                    {item["Short Description"] && (
                      <p className="avissi-short-description">
                        {item["Short Description"]}
                      </p>
                    )}

                    {item.Descrizione && (
                      <p className="avissi-descrizione">
                        <strong>Descrizione:</strong> {item.Descrizione}
                      </p>
                    )}

                    {item.Soggetto_Concedente && (
                      <p className="avissi-soggetto">
                        <strong>Soggetto Concedente:</strong>{" "}
                        {item.Soggetto_Concedente}
                      </p>
                    )}

                    {item.Link_istituzionale && (
                      <p className="avissi-link-istituzionale">
                        <strong>Link Istituzionale:</strong>{" "}
                        <a
                          href={item.Link_istituzionale}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.Link_istituzionale}
                        </a>
                      </p>
                    )}

                    {/* Render only non-empty criteria */}
                    {criteriaKeys.length > 0 && (
                      <div className="avissi-criteria">
                        <strong>Criteri / Requisiti:</strong>
                        <ul>
                          {criteriaKeys.map((key) => (
                            <li key={key}>{item[key]}</li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {/* Date Section */}
                    <div className="avissi-dates">
                      {item.data_apertura && (
                        <p className="avissi-date">
                          <strong>Data di Apertura:</strong>{" "}
                          {new Date(item.data_apertura.seconds * 1000).toLocaleDateString()}
                        </p>
                      )}
                      {item.data_chiusura && (
                        <p className={`avissi-date ${closed ? "date-closed" : ""}`}>
                          <strong>Data di Chiusura:</strong>{" "}
                          {new Date(item.data_chiusura.seconds * 1000).toLocaleDateString()}
                        </p>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p className="avissi-error">
              Nessun avviso disponibile per il filtro selezionato.
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Formazione;
import React, { useState, useEffect } from "react";
import { auth, googleProvider, db, storage } from "../config/firebase.js";
import {
  getFirestore,
  query,
  where,
  doc,
  setDoc,
  collection,
  getDocs,
  addDoc,
  getDoc,
  updateDoc,
  doc as firestoreDoc,
  deleteDoc,
  serverTimestamp,
  orderBy,
  arrayUnion,
  arrayRemove,
  onSnapshot,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Messages = () => {
  const [userId, setUserId] = useState(auth.currentUser?.uid || null);
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [emailContent, setEmailContent] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [piva, setpiva] = useState("");
  const senderEmail = "info@incentivato.it";
  const [introduction, setIntroduction] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [signoff, setSignoff] = useState("");
  const [finalMessage, setFinalMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        //console.log("User logged in:", user.uid);
        setUserId(user.uid);
      } else {
        console.log("No user logged in.");
        setUserId(null);
      }
    });
    return () => unsubscribe();
  }, []);

  // Fetch all messages for the current user
  useEffect(() => {
    if (!userId) return;

    const messagesRef = collection(db, "users_messages");
    const q = query(messagesRef, where("userId", "==", userId));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(fetchedMessages);
    });

    return () => unsubscribe();
  }, [userId]);

  const handleDeleteMessage = async (messageId) => {
    try {
      const messageRef = doc(db, "users_messages", messageId);
      await deleteDoc(messageRef);
      //console.log(`Message with ID ${messageId} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  // Generate the tracking URL based on userId, piva, and link
  const generateTrackingUrl = (userId, piva, link) => {
    return `https://incentiveunic.incentivato.it/track-click?userId=${encodeURIComponent(
      userId
    )}&piva=${encodeURIComponent(piva)}&link=${encodeURIComponent(link)}`;
  };

  const composeAndSendMessage = async () => {
    // Ensure messageBody is populated
    if (!messageBody?.trim()) {
      alert("Message body is empty. Please generate the message first.");
      return;
    }
  
    // Start loading spinner
    setLoading(true);
  
    try {
      const intro = String(introduction || "");
      const sign = String(signoff || "");
  
      // Retrieve updated incentives from localStorage
      const incentives = JSON.parse(localStorage.getItem("selectedIncentives")) || [];
      const piva = localStorage.getItem("piva");
  
      if (!piva) {
        alert("PIVA is missing. Please add an incentive first.");
        throw new Error("Missing PIVA in localStorage.");
      }
  
      if (incentives.length === 0) {
        alert("No incentives found to include in the message.");
        throw new Error("No incentives in localStorage.");
      }
  
      // Construct the body with correct tracking URLs using OpenAI response fields
      const formattedBody = incentives
        .map((incentive, index) => {
          const { incentive_id, titolo, summary, key_benefit, tracking_url, regime } = incentive;
          const paragraphEmoji = index % 2 === 0 ? "💡" : "🚀";
  
          return `${paragraphEmoji} <strong><a href="${tracking_url}" target="_blank" style="text-decoration: none; color: #007BFF;">Incentivo ${incentive_id}</a>:</strong> ${titolo}<br/><br/>
          📝 <strong>Dettagli:</strong> ${summary}<br/><br/>
          🎯 <strong>Beneficio chiave:</strong> ${key_benefit}<br/><br/>
          🏛️ <strong>Regime:</strong> ${regime}<br/><br/>
          🔗 <a href="${tracking_url}" target="_blank" style="text-decoration: underline; color: #28a745;">Clicca qui per maggiori dettagli</a><br/><br/>`;
        })
        .join('<hr style="border: 1px solid #ccc;"/>');
  
      // Compose the final message with better formatting
      const composedMessage = `<div style="font-family: Arial, sans-serif; color: #333;">
        <p style="margin-bottom: 20px;">${intro}</p>
        ${formattedBody}
        <p style="margin-top: 20px;">${sign}</p>
      </div>`;
  
      //console.log("Composed final message:", composedMessage);
  
      if (composedMessage.trim().length === 0) {
        alert("The composed message is empty. Please check the data.");
        throw new Error("Composed message is empty. Check incentive data or OpenAI response.");
      }
  
      await handleSendMessage(composedMessage, piva);
      alert("Message sent successfully!");
  
    } catch (error) {
      console.error("Error while sending message:", error);
      alert("Failed to send the message. Please try again.");
    } finally {
      // Stop loading spinner
      setLoading(false);
    }
  };
  
  



  //////////// Handle sending a new message
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const handleSendMessage = async (messageToSend, piva) => {
    if (!messageToSend?.trim() || !recipientEmail?.trim() || !userId) {
      alert(
        "Please enter all required fields: user ID, recipient email, and message content."
      );
      return;
    }

    /* console.log("Sending message with:", {
      userId,
      recipientEmail,
      messageToSend,
      piva
    }); */ 

    try {
      const response = await fetch(`${API_BASE_URL}/api/send-scout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: userId,
          recipient_email: recipientEmail,
          message_content: messageToSend,
          piva: piva
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setFinalMessage("");
        setRecipientEmail("");
        alert("Message sent successfully!");

        // Clear the incentives from localStorage after successful submission
        localStorage.removeItem("selectedIncentives");
        //console.log("Cleared selected incentives from localStorage.");
      } else {
        throw new Error(data.error || "Failed to send message.");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Failed to send message.");
    }
  };

  ////* Leads
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    if (!userId) return;

    const leadsRef = collection(db, "Leads");
    const q = query(leadsRef, where("userId", "==", userId));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const leadsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeads(leadsData);
    });

    return () => unsubscribe();
  }, [userId]);

  ////////////////////////////////////////////////////////////
  //buttons

  const handleDeleteLead = async (leadId) => {
    if (window.confirm("Are you sure you want to delete this lead?")) {
      try {
        await deleteDoc(doc(db, "Leads", leadId));
        //console.log("Lead deleted:", leadId);
      } catch (error) {
        console.error("Error deleting lead:", error);
      }
    }
  };

  //////////
  const incentivesToSend = [];

  const handleAddToEmail = async (id, link, userId, piva) => {
    //console.log("Original link from Firebase:", link);

    // Add piva to localStorage if not already present
    localStorage.setItem("piva", piva);

    // Generate tracking URL
    const trackingUrl = generateTrackingUrl(userId, piva, link);
    //console.log("Generated tracking URL:", trackingUrl);

    setNewMessage((prev) =>
      prev
        ? `${prev}\n<a href="${trackingUrl}" target="_blank">${id}</a>`
        : `<a href="${trackingUrl}" target="_blank">${id}</a>`
    );

    // Fetch incentive details from Firestore
    try {
      const incentiveRef = doc(db, "incentivi", id);
      const incentiveSnap = await getDoc(incentiveRef);

      if (incentiveSnap.exists()) {
        const { Titolo, Summary, KeyBenefit } = incentiveSnap.data();
/*         console.log(`Incentive ID: ${id}`);
        console.log(`Titolo: ${Titolo}`);
        console.log(`Summary: ${Summary}`);
        console.log(`KeyBenefit: ${KeyBenefit}`); */

        // Retrieve existing incentives from localStorage
        const storedIncentives =
          JSON.parse(localStorage.getItem("selectedIncentives")) || [];

        // Add the new incentive along with its tracking URL
        storedIncentives.push({
          incentive_id: id,
          titolo: Titolo,
          summary: Summary,
          key_benefit: KeyBenefit,
          tracking_url: trackingUrl,
        });

        // Save back to localStorage
        localStorage.setItem(
          "selectedIncentives",
          JSON.stringify(storedIncentives)
        );

        //console.log("Updated localStorage with incentives:", storedIncentives);
      } else {
        console.log(`No incentive found for ID: ${id}`);
      }
    } catch (error) {
      console.error("Error fetching incentive data:", error);
    }
  };

  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    const storedIncentives =
      JSON.parse(localStorage.getItem("selectedIncentives")) || [];
    const storedIds = storedIncentives.map((inv) => inv.incentive_id);
    setSelectedIds(storedIds);
  }, []);

  // Handle click on the add/remove button
  const handleToggle = (lead) => {
    const { ID_Incentivo, link, piva } = lead;

    // Check if the ID is already selected
    if (selectedIds.includes(ID_Incentivo)) {
      // Remove from localStorage
      const storedIncentives =
        JSON.parse(localStorage.getItem("selectedIncentives")) || [];
      const updatedIncentives = storedIncentives.filter(
        (inv) => inv.incentive_id !== ID_Incentivo
      );
      localStorage.setItem(
        "selectedIncentives",
        JSON.stringify(updatedIncentives)
      );

      // Remove from state
      setSelectedIds((prev) => prev.filter((id) => id !== ID_Incentivo));
      //console.log(`Removed incentive ${ID_Incentivo} from localStorage.`);
    } else {
      // Add to localStorage
      handleAddToEmail(ID_Incentivo, link, userId, piva);
      setSelectedIds((prev) => [...prev, ID_Incentivo]);
      //console.log(`Added incentive ${ID_Incentivo} to localStorage.`);
    }
  };

  //// OPENAI CALL

  const [loadingGenerate, setLoadingGenerate] = useState(false);

  const handleOAIcall = async () => {
    // Retrieve incentives from localStorage
    const incentivesToSend =
      JSON.parse(localStorage.getItem("selectedIncentives")) || [];
  
    if (incentivesToSend.length === 0) {
      console.warn("No incentives to send.");
      alert("No incentives selected to send.");
      return;
    }
  
    //console.log("Sending to server:", incentivesToSend);
  
    // Start loading spinner
    setLoadingGenerate(true);
  
    try {
      const response = await fetch(`${API_BASE_URL}/api/generatescoutmessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ incentives: incentivesToSend }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        alert("Message content generated successfully!");
        setMessageBody(data.email_content);
  
        // Parse OpenAI response and update localStorage
        const responseText = data.email_content;
        //console.log("OpenAI response:", responseText);
  
        const updatedIncentives = incentivesToSend.map((incentive) => {
          const regex = new RegExp(`Incentivo ${incentive.incentive_id}:([\\s\\S]*?)Regime: (.*)`, 'g');
          const match = regex.exec(responseText);
  
          if (match) {
            const dettagliMatch = match[1].match(/Dettagli:\s(.*?)\sBeneficio/);
            const beneficioMatch = match[1].match(/Beneficio chiave:\s(.*?)$/);
            const regimeMatch = match[2];
  
            return {
              ...incentive,
              summary: dettagliMatch ? dettagliMatch[1].trim() : incentive.summary,
              key_benefit: beneficioMatch ? beneficioMatch[1].trim() : incentive.key_benefit,
              regime: regimeMatch || "Non 100% chiaro se rientra/non-rientra nel regime \"de minimis\"",
            };
          }
  
          return incentive;
        });
  
        // Update localStorage with parsed OpenAI response
        localStorage.setItem("selectedIncentives", JSON.stringify(updatedIncentives));
        //console.log("Updated incentives with OpenAI response:", updatedIncentives);
  
      } else {
        throw new Error(data.error || "Failed to generate message content.");
      }
    } catch (error) {
      console.error("Error generating message content:", error);
      alert("Failed to generate message content.");
    } finally {
      // Stop loading spinner
      setLoadingGenerate(false);
    }
  };
  

  const [showTags, setShowTags] = useState(false);

  // Toggle the visibility of the tags section
  const toggleTags = () => {
    setShowTags(!showTags);
  };

  const [showDetails, setShowDetails] = useState(false);

  // Toggle the visibility of the message details section
  const toggleDetails = () => setShowDetails(!showDetails);

  const handleMessageClick = (message) => {
    setSelectedMessage(message);
  };

  ////////JSX

  return (
    <div style={{backgroundColor: "black"}}>
    <div style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "45px",
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
    backgroundColor: "black",
    marginTop: "10px"
  }}>
      Message Scouting
      </div>
    <div className="messagesmg-container">
      {/* Sidebar for Historical Messages */}
      <div className="main-contentmm">
      <h3>Messaggi</h3>
      <div className="sidebarmg">
        <div style={{marginLeft: "30px"}}>scegli uno</div>
        <div
          style={{
            maxHeight: "220px",
            overflowY: "auto",
            padding: "10px",
            marginRight: "20px",
          }}
        >
          <ul>
            {messages.slice(-100).map((msg) => (
              <li
                key={msg.id}
                className={`sidebarmg-item ${
                  selectedMessage?.id === msg.id ? "active" : ""
                }`}
                onClick={() => setSelectedMessage(msg)}
                style={{ marginBottom: "10px", listStyle: "none" }}
              >
                <p style={{ margin: 0 }}>{msg.recipientEmail}</p>
                <span
                  className="timestampmg"
                  style={{ fontSize: "12px", color: "#555" }}
                >
                  {msg.timestamp?.toDate()?.toLocaleString() || "Loading..."}
                </span>
                <button
                  style={{
                    background: "none",
                    border: "none",
                    color: "red",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the click from selecting the message
                    handleDeleteMessage(msg.id);
                  }}
                >
                  🗑️
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <button className="close-details-button" onClick={toggleDetails}>
            ❌ Close
          </button>
          {selectedMessage ? (
            <div className="message-details-content">
              <h4>📨 {selectedMessage.recipientEmail}</h4>
              <h4>
                🕒{" "}
                {selectedMessage.timestamp?.toDate()?.toLocaleString() ||
                  "Loading..."}
              </h4>

              <div
                className="message-html"
                dangerouslySetInnerHTML={{ __html: selectedMessage.message }}
              />
            </div>
          ) : (
            <p>No message selected.</p>
          )}
        </div>
      </div>
      </div>
      <div style={{ display: "block", width: "100%" }}>
        {/* Main Content for Selected Message */}
        <div className="main-contentmg">
          <h3>Nuovo</h3>
          {/* Message Input */}
          <div className="email-container">
            {/* Recipient & Sender Row */}
            <div className="email-inputs-row">
              <div className="email-input">
                <label style={{color: "white"}}>📬 Destinatario:</label>
                <input
                  type="email"
                  value={recipientEmail}
                  onChange={(e) => setRecipientEmail(e.target.value)}
                  placeholder="Inserisci l'e-mail del destinatario"
                />
              </div>

              <div className="email-input">
                <label style={{color: "white"}}>📤 Sender:</label>
                <input
                  type="email"
                  value={senderEmail}
                  readOnly
                  placeholder="Your email (auto-filled)"
                />
              </div>
            </div>

            {/* Message Sections */}
            <div className="message-sections">
              {/* Introduction */}
              <div className="message-box">
                <label style={{color: "white"}}>📝 Intro:</label>
                <textarea
                  value={introduction}
                  onChange={(e) => setIntroduction(e.target.value)}
                  placeholder="Aggiunge intro..."
                />
              </div>
              <div className="button-row">
       
                <button className="tags-button" onClick={toggleTags}>
                  🔖 Toggle Tags
                </button>
                <button
                  className="generate-button"
                  onClick={handleOAIcall}
                  disabled={loadingGenerate}
                >
                  {loadingGenerate ? (
                    <div className="spinner"></div>
                  ) : (
                    <>🚀 Generate Body</>
                  )}
                </button>

              </div>

              {/* Message Body Preview */}
              <div className="message-box">
                <label style={{color: "white"}}>📧 Body (Generated):</label>
                <div
                  className="email-preview"
                  dangerouslySetInnerHTML={{ __html: messageBody }}
                />
              </div>

              {/* Signoff */}
              <div className="message-box">
                <label style={{color: "white"}}>👋 Signoff:</label>
                <textarea
                  value={signoff}
                  onChange={(e) => setSignoff(e.target.value)}
                  placeholder="Aggiunge signoff..."
                />
              </div>

              {/* Send Message Button */}
              <button
                className="send-button"
                onClick={composeAndSendMessage}
                disabled={loading}
              >
                {loading ? (
                  <div className="spinner"></div>
                ) : (
                  <>✉️ Send Message</>
                )}
              </button>

            </div>
          </div>

          {/*   <div className="messagemg-input">
    <div className="email-preview" dangerouslySetInnerHTML={{ __html: newMessage }} />
    <textarea
      value={newMessage}
      onChange={(e) => setNewMessage(e.target.value)}
      placeholder="Write your message..."
    />
    <button onClick={handleSendMessage}>Send Message</button>
  </div> */}
        </div>
        <div className={`tags-section ${showTags ? "show" : ""}`}>
          <div className="tags-content">
            <div style={{display:"flex", gap: "20px"}}>
            <h2>🏷️ TAGS</h2>
            <button className="tags-button" onClick={toggleTags}>
                  🔖 Toggle Tags
                </button>
                </div>
            {leads.length > 0 ? (
              <table className="tags-table">
                <thead>
                  <tr>
                    <th>Incentivo</th>
                    <th>P.IVA</th>
                    <th>Impresa</th>
                    <th>Date</th>
                    <th>Link</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {leads.map((lead) => (
                    <tr key={lead.id}>
                      <td>{lead.ID_Incentivo} - {lead.Titolo?.slice(0, 30)}{lead.Titolo?.length > 30 ? "..." : ""}</td>
                      <td>{lead.piva}</td>
                      <td>{lead.companyName?.slice(0, 30)}{lead.companyName?.length > 30 ? "..." : ""}</td>
                      <td>{lead.date?.toDate().toLocaleString()}</td>
                      <td>
                        <a
                          href={lead.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      </td>
                      <td>
                        {/* 🗑 Delete Button */}
                        <button
                          onClick={() => handleDeleteLead(lead.id)}
                          className="delete-button"
                        >
                          🗑
                        </button>

                        {/* ➕/✅ Add to Email Button */}
                        <button
                          onClick={() => handleToggle(lead)}
                          className={`toggle-button ${
                            selectedIds.includes(lead.ID_Incentivo)
                              ? "selected"
                              : ""
                          }`}
                          title={
                            selectedIds.includes(lead.ID_Incentivo)
                              ? "Selected"
                              : "Add to Email"
                          }
                        >
                          {selectedIds.includes(lead.ID_Incentivo)
                            ? "✅"
                            : "➕"}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No leads found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Messages;

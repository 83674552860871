import React, { useState, useEffect } from "react";
import { auth, googleProvider, db } from "../config/firebase.js";
import {
  getFirestore,
  query,
  where,
  doc,
  collection,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Users from "./users.js";
import { useAuth } from "../../src/components/auth-email.js";
import ToggleSwitch from "../components/toggle.js";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  LineChart,
} from "recharts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//../src/components/userPrompt.js";
import thumbup from "..//../src/thumbup.svg";
import thumbdown from "..//../src/thumbdown.svg";
import rightarrow from "..//../src/right_arrow.svg";
import { Spinner } from "react-bootstrap";
import { signOut } from "firebase/auth";
import AvatarSelector from ".//..//../src/components/avatar.js";
import Storage from ".//..//../src/pages/folderstorage.js";
import VendorCV from "./vendorCV.js";
import RunCVButton from "../components/runCVButton.js";
import IncentiveSelector from "../components/incentiveSelector.js";

function PartnerPortal() {
  const { currentUser } = useAuth(); // Using a custom hook to get the current user
  const [atecoL3, setAtecoL3] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyRole, setCompanyRole] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyComune, setCompanyComune] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyPiva, setCompanyPiva] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [currentUserID, setCurrentUserID] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState(0);
  const [trustPilot, setTrustPilot] = useState(0);
  const [summary, setSummary] = useState(0);
  const [years, setYears] = useState(0);
  const [cw, setCw] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [services, setServices] = useState([]);
  const [incentives, setIncentives] = useState([]);
  const [phone, setPhone] = useState("");
  const [logo, setLogo] = useState("");

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      if (currentUser && currentUser.email) {
        try {
          const usersCollectionRef = collection(db, "users");
          const q = query(
            usersCollectionRef,
            where("email", "==", currentUser.email)
          );
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();

            if (userData.avatar) {
              setSelectedAvatar(userData.avatar); // Set existing avatar
              setAvatarSelected(true); // Skip Avatar Selector
            } else {
              setAvatarSelected(false); // Show Avatar Selector
            }

            // Check the role of the user
            if (userData.role === "partner") {
              // Set state for partner-specific fields
              setFirstName(userData.firstName || "N/A");
              setLastName(userData.lastName || "N/A");
              setCompanyEmail(userData.email || "N/A");
              setRegione(userData.regione || "N/A");
              setServices(
                Array.isArray(userData.services) ? userData.services : []
              );
              setIncentives(userData.incentives || []);
              setCompanyRole(userData.role || "N/A");
              setTrustPilot(userData.trustPilot || "N/A");
              setSummary(userData.summary || "N/A");
              setYears(userData.years || "N/A");
              setPhone(userData.phone || "N/A");
              setLogo(userData.logo || "");
            } else {
              // Set state for non-partner users
              setAtecoL3(userData.companyAteco || "");
              setRegione(userData.regione || "");
              setFirstName(userData.firstName || "");
              setLastName(userData.lastName || "");
              setCompanyRole(userData.role || "");
              setCompanyEmail(userData.email || "");
              setCompanyComune(userData.comune || "");
              setCompanyWebsite(userData.website || "");
              setCompanyPiva(userData.piva || "");
              setCompanyName(userData.companyName || "");
              setCompanySize(userData.companySize || "");
              setCompanyEmployees(userData.companyEmployees || "");
              setCompanyTurnover(userData.companyTurnover || 0);

              // Fetch service data if relevant
              if (userData.service) {
                await fetchServiceData(userData.service);
              }
            }

            setCurrentUserID(userDoc.id); // Set the user ID regardless of role
          } else {
            console.log("No user data found for email:", currentUser.email);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    }

    // Function to fetch service data
    async function fetchServiceData(serviceId) {
      try {
        const serviceDocRef = doc(db, "services", serviceId);
        const serviceDoc = await getDoc(serviceDocRef);
        if (serviceDoc.exists()) {
          setServices([serviceDoc.data()]);
        } else {
          console.log("No service found with ID:", serviceId);
        }
      } catch (error) {
        console.error("Error fetching service data:", error);
      }
    }

    fetchUserData();
  }, [currentUser]);

  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim(); // Trim whitespace at the start and end

      // Remove everything before and including the first colon on the first line
      desc = desc.replace(/^[^:]*:\s*/, "");

      // Normalize new lines and handle the initial bullet point
      let lines = desc.split(/\n+/); // Split the description into lines
      lines = lines.reduce((filteredLines, line, index) => {
        line = line.trim(); // Trim each line to remove leading/trailing whitespace

        // Skip empty lines to avoid adding extra spaces
        if (line === "") {
          return filteredLines;
        }

        // Handle numbered lines to not start with a bullet
        if (/^\d+\./.test(line)) {
          filteredLines.push(line); // Return the line as is if it starts with a number and a period
        } else if (index > 0 && filteredLines.length > 0) {
          // Apply bullets to lines that are not the first line and not after an empty line
          filteredLines.push("• " + line);
        } else {
          filteredLines.push(line); // First line or any line not fitting the above criteria gets no bullet
        }

        return filteredLines;
      }, []);

      // Join the lines back together, inserting a newline between each
      return lines.join("\n");
    }
    return "No description available"; // Return a default message if no description is provided
  };

  const extractBase = (ateco) => {
    // Regex to capture two digits before and one or two digits after the decimal point
    const numericalPartMatch = ateco.match(/^(\d{2}\.\d{1,2})/);
    let numericalPart = "";

    if (numericalPartMatch) {
      numericalPart = numericalPartMatch[1];
      const decimalParts = numericalPart.split(".");

      // Ensure two decimal places by padding with a zero if necessary
      if (decimalParts[1].length === 1) {
        numericalPart = `${decimalParts[0]}.${decimalParts[1]}0`;
      }
    }

    return numericalPart;
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const navigate = useNavigate();
  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (err) {
      console.error("Error logging out:", err);
    }
  };

  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [avatarSelected, setAvatarSelected] = useState(false);

  const handleAvatarSelect = async (avatarSrc) => {
    setSelectedAvatar(avatarSrc);
    setAvatarSelected(true); // Hide avatar selector after selection

    if (!currentUserID) {
      console.error("User ID not available. Cannot update avatar.");
      return;
    }

    // Upload to Firebase
    const userRef = doc(db, "users", currentUserID);

    try {
      await updateDoc(userRef, {
        avatar: avatarSrc, // Save avatar URL/path
      });
      console.log("Avatar uploaded to Firebase:", avatarSrc);
    } catch (error) {
      console.error("Error uploading avatar:", error);
    }
  };

  const [cvProcessed, setCVProcessed] = useState(false);
  const [showCV, setShowCV] = useState(true); // Initially showing the CV box
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userId = user.uid;
        setCurrentUserID(userId);

        try {
          const userRef = doc(db, "users", userId);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const userData = userSnap.data();

            // Check if 'summary' exists to determine if CV is processed
            if (userData.summary && userData.summary.trim() !== "") {
              setCVProcessed(true);
              setShowCV(false); // Hide CV box if processed
            } else {
              setCVProcessed(false); // Not processed yet
              setShowCV(true); // Show CV box for processing
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        } finally {
          setLoading(false); // Stop loading
        }
      } else {
        console.log("No authenticated user found.");
        setLoading(false); // Stop loading even if no user
      }
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, [auth, db]);

  // Called after successful CV processing
  const handleCVProcessed = () => {
    setCVProcessed(true);
    setShowCV(false);
  };

  // Toggle visibility of CV box
  const toggleCVVisibility = () => {
    setShowCV((prev) => !prev);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="searchy-container">
      {!isAuthenticated && <UserPromptModal />}

      <div className="searchy-containered" style={{ background: "black" }}>
        <div>
          <div className="agent2-containers">
            <div
              className="incentivy-container"
              style={{ background: "black" }}
            >
              {/* Check if the user is a partner */}
              {companyRole === "partner" ? (
                <div>
                  <div>
                    {!avatarSelected ? (
                      <AvatarSelector onAvatarSelect={handleAvatarSelect} />
                    ) : (
                      <div className="avatar-confirmation">
                        <h3 style={{ color: "white" }}>
                          {firstName} {lastName}
                        </h3>
                        <img
                          src={selectedAvatar}
                          alt="Selected Avatar"
                          className="selected-avatar-preview"
                        />
                      </div>
                    )}
                  </div>
                  <div className="centered-cvbox">
                    {/* Toggle Button to Show/Hide CV */}
                    {cvProcessed && (
                      <button
                        className="toggle-button"
                        onClick={toggleCVVisibility}
                      >
                        {showCV ? "Nascondi CV 📁" : "Mostra CV 📁"}
                      </button>
                    )}

                    {/* CV Box and RunCVButton (Hidden After Processing) */}
                    {(!cvProcessed || showCV) && (
                      <>
                        {showCV && <VendorCV />}

                        {!cvProcessed && currentUserID ? (
                          <RunCVButton
                            userId={currentUserID}
                            onCVProcessed={handleCVProcessed}
                          />
                        ) : (
                          !cvProcessed && <p>Loading user information...</p>
                        )}
                      </>
                    )}
                  </div>

                  {logo && (
                    <div style={{ textAlign: "center", margin: "20px 0" }}>
                      <img
                        src={logo}
                        alt={`${firstName} ${lastName} Logo`}
                        style={{
                          maxWidth: "200px",
                          height: "auto",
                          borderRadius: "8px",
                          border: "2px solid white",
                        }}
                      />
                    </div>
                  )}
                  <div className="profile-container">
                    <div className="profile-card">
                      <h2 className="profile-heading">Profilo</h2>

                      {/* Email */}
                      <div className="profile-field">
                        <div className="field-label">Email</div>
                        <div className="field-content">{companyEmail}</div>
                      </div>

                      {/* Role */}
                      <div className="profile-field">
                        <div className="field-label">Role</div>
                        <div className="field-content">{companyRole}</div>
                      </div>

                      {/* Regione */}
                      <div className="profile-field">
                        <div className="field-label">Regione</div>
                        <div className="field-content">{regione}</div>
                      </div>

                      {/* Years */}
                      <div className="profile-field">
                        <div className="field-label">Anni</div>
                        <div className="field-content">{years}</div>
                      </div>

                      {/* Summary */}
                      <div className="profile-field summary-box">
                        <div className="field-label rotated-label">Summary</div>
                        <div className="field-content">{summary}</div>
                      </div>

                      {/* Services */}
                      <div className="profile-field">
                        <div className="field-label">Services</div>
                        <div className="services-container">
                          {Array.isArray(services) && services.length > 0 ? (
                            services.map((service, index) => (
                              <div key={index} className="incentive-box">
                                {service}
                              </div>
                            ))
                          ) : (
                            <p className="no-services">No services listed.</p>
                          )}
                        </div>
                      </div>

                      {/* TrustPilot */}
                      <div className="profile-field">
                        <div className="field-label">TrustPilot</div>
                        <div className="field-content">
                          {trustPilot || "N/A"}
                        </div>
                      </div>

                      {/* Incentives */}
                      <div className="profile-field incentives-box">
                        <div className="field-label">
                          Incentivi che hai gestito
                        </div>
                        <div className="field-content">
                          <IncentiveSelector
                            userId={currentUserID}
                            existingIncentives={incentives}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {/* Default layout for other roles */}
                  <h1
                    style={{
                      margin: "10px 10px 10px 10px",
                      color: "white",
                      textAlign: "center",
                      fontSize: "28px",
                      lineHeight: "2rem",
                    }}
                  ></h1>
                  <h2
                    style={{
                      margin: "10px 10px 10px 10px",
                      color: "white",
                      textAlign: "center",
                      fontSize: "22px",
                      lineHeight: "2rem",
                      backgroundColor: "#333",
                      padding: "15px",
                      borderRadius: "8px",
                      margin: "10px",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontSize: "16px",
                        lineHeight: "2rem",
                        fontWeight: "normal",
                      }}
                    >
                      <br />
                      Role:{" "}
                      <span style={{ color: "#FF5758" }}>{companyRole}</span>
                      <br />
                      Email:{" "}
                      <span style={{ color: "#FF5758" }}>{companyEmail}</span>
                      <br />
                      Partita Iva:{" "}
                      <span style={{ color: "#FF5758" }}>{companyPiva}</span>
                      <br />
                      Regione:{" "}
                      <span style={{ color: "#FF5758" }}>{regione}</span>
                      <br />
                      Comune:{" "}
                      <span style={{ color: "#FF5758" }}>{companyComune}</span>
                      <br />
                      Ateco:{" "}
                      <span style={{ color: "#FF5758" }}>
                        {extractBase(atecoL3)}
                      </span>
                      <br />
                      Dimensione:{" "}
                      <span style={{ color: "#FF5758" }}>{companySize}</span>
                      <br />
                      Turnover:{" "}
                      <span style={{ color: "#FF5758" }}>
                        {companyTurnover}
                      </span>
                      <br />
                      Dipendenti:{" "}
                      <span style={{ color: "#FF5758" }}>
                        {companyEmployees}
                      </span>
                      <br />
                      Website:{" "}
                      <span style={{ color: "#FF5758" }}>{companyWebsite}</span>
                      <br />
                    </span>
                    <div
                      className="service-container"
                      style={{ marginTop: "20px", color: "white" }}
                    >
                      <h3 style={{ textAlign: "center", fontSize: "24px" }}>
                        Current Services
                      </h3>
                    </div>
                  </h2>
                </div>
              )}
              <button
                onClick={logout}
                className="logout-button"
                style={{ marginLeft: "3px" }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerPortal;

import React from 'react';
import { useNavigate } from 'react-router-dom';

const Marketplace = () => {
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      {/* Professionisti Button */}
      <div style={styles.card}>
        <h2>Professionisti</h2>
        <p>Offriamo servizi di agevolazione per la tua azienda.</p>
        <button style={styles.button} onClick={() => navigate('/marketplace/agevolazione')}>
          Scopri Agevolazioni
        </button>
      </div>

      {/* Formazione Button */}
      <div style={styles.card}>
        <h2>Formazione</h2>
        <p>Offriamo corsi di formazione specializzati.</p>
        <button style={styles.button} onClick={() => navigate('/marketplace/formazione')}>
          Scopri Corsi
        </button>
      </div>
    </div>
  );
};

// Inline Styles for Simplicity
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '50px',
    marginBottom: '50px',
  },
  card: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    width: '300px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  button: {
    marginTop: '15px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default Marketplace;

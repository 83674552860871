import React, { useState, useEffect } from "react";
import { db } from "../config/firebase.js";
import { collection, query, onSnapshot } from "firebase/firestore";
import "./formazione.css";

const FormazioneMP = () => {
  const [trainings, setTrainings] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");

  // Updated categories
  const categories = [
    "Intelligenza Artificiale",
    "Blockchain",
    "Welfare",
    "Start-up",
    "Psicologia",
    "Soft Skills",
    "Digitalizzazione",
    "Innovazione",
    "Leadership",
  ];

  useEffect(() => {
    // Fetch training data from Firestore
    const fetchData = async () => {
      try {
        const q = query(collection(db, "Formazione"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          const trainingList = [];
          querySnapshot.forEach((doc) => {
            trainingList.push({ id: doc.id, ...doc.data() });
          });
          setTrainings(trainingList);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching training data:", error);
      }
    };

    fetchData();
  }, []);

  // Filter training based on selected category
  const filteredTrainings =
    selectedCategory === "All"
      ? trainings
      : trainings.filter((training) =>
          training.category.includes(selectedCategory)
        );

  return (
    <div className="formazione-container">
      {/* Header Section */}
      <div className="formazione-header">
        <h1 className="formazione-title">Formazione Finanziata</h1>
        <p className="formazione-description">
          Scopri le opportunità di formazione finanziata per migliorare le tue
          competenze e far crescere la tua azienda.
        </p>
      </div>

      <div className="formazione-layout">
        {/* Vertical Menu */}
        <div className="formazione-menu">
          <ul>
            <li
              className={selectedCategory === "All" ? "active" : ""}
              onClick={() => setSelectedCategory("All")}
            >
              Tutti
            </li>
            {categories.map((category) => (
              <li
                key={category}
                className={selectedCategory === category ? "active" : ""}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>

        {/* Training Opportunities */}
        <div className="formazione-content">
          <div className="formazione-grid">
            {filteredTrainings.length > 0 ? (
              filteredTrainings.map((training) => (
                <div key={training.id} className="training-card">
                  <img
                    src={training.image}
                    alt={training.title}
                    className="training-image"
                  />
                  <h3 className="training-title">{training.title}</h3>
                  <p className="training-description">{training.description}</p>
                  <div className="training-details">
                    <p>
                      <strong>Categoria:</strong> {training.category}
                    </p>
                    <p>
                      <strong>Durata:</strong> {training.duration}
                    </p>
                    <p>
                      <strong>Scadenza:</strong>{" "}
                      {new Date(training.end_date).toLocaleDateString()}
                    </p>
                    <p>
                      <strong>Prezzo:</strong> €{training.price.toFixed(2)}
                      {training.discount > 0 && (
                        <span className="discount">
                          {" "}
                          (-{training.discount}%)
                        </span>
                      )}
                    </p>
                    <p>
                      <strong>Trainer:</strong>{" "}
                      <a
                        href={training.trainer_profile}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {training.trainer_name}
                      </a>
                    </p>
                  </div>
                  <button
                    className="training-button"
                    onClick={() => window.open(training.traininglink, "_blank")}
                  >
                    Maggiori Informazioni
                  </button>
                </div>
              ))
            ) : (
              <p className="formazione-error">
                Nessuna formazione disponibile al momento.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormazioneMP;